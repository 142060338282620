import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Typography, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FORM_VALIDATOR,
  USER_ACTIONS,
  USER_VERIFY_TYPE,
} from "../../constant/constants";
import { appActions } from "../../store/app.reducer";
import { newUser } from "../../store/user.effect";

const { Title } = Typography;

const passMatch = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter matching password."));
  },
});

const SignUpComponent = () => {
  const [formFilled, setFormFilled] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    setFormFilled(true);
    const { confirmPass, ...rest } = values;
    dispatch(appActions.setIsLoading(true));

    newUser(rest)()
      .then((data) => {
        if (data.statusCode === 200) {
          navigate("/signUpSuccess", {
            replace: true,
            state: {
              action: USER_ACTIONS.internal,
              verifyType: USER_VERIFY_TYPE.internal,
            },
          });
        } else if (data.statusCode === 409) {
          message.warning("User already exist, please sign in");
        } else {
          message.error(`Failed to register user`);
        }
        form.resetFields();
      })
      .finally(() => {
        dispatch(appActions.setIsLoading(false));
      });
  };

  const onFormChanges = (_, allValues) => {
    const { lastName, ...mandatoryFields } = allValues;
    setFormFilled(
      Object.values(mandatoryFields).every((it) => it) &&
        mandatoryFields.password === mandatoryFields.confirmPass
    );
  };

  const navigateToSignIn = () => {
    navigate("/");
  };

  return (
    <section className="signup-wrapper">
      <header className="header-wrapper">
        <div className="logo-container">
          <img src="assets/logo.png" alt="WFC" />
        </div>
        <div className="signup-nav">
          <Title level={5} className="no-margin">
            Have an account?{" "}
          </Title>{" "}
          <Button className="signIn-btn" onClick={navigateToSignIn}>
            Sign In
          </Button>
        </div>
      </header>
      <main className="signup-middle">
        <div className="signup-card">
          <Title level={3} className="signup-card-head">
            {" "}
            Let's Sign You Up !
          </Title>
          <Form
            form={form}
            name="signup"
            validateMessages={FORM_VALIDATOR}
            onFinish={onFinish}
            onValuesChange={onFormChanges}
          >
            <div className="name-group">
              <Form.Item name="firstName" rules={[{ required: true }]}>
                <Input size="large" placeholder="First Name" />
              </Form.Item>
              <Form.Item name="lastName">
                <Input size="large" placeholder="Last Name (optional)" />
              </Form.Item>
            </div>
            <Form.Item name="email" rules={[{ type: "email", required: true }]}>
              <Input size="large" placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password." },
              ]}
              hasFeedback
            >
              <Input.Password
                size="large"
                placeholder="Enter Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirmPass"
              rules={[
                { required: true, message: "Please confirm your password." },
                passMatch,
              ]}
              dependencies={["password"]}
              hasFeedback
            >
              <Input.Password
                size="large"
                placeholder="Confirm Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item className="signup-btn-container" noStyle>
              <Button
                type="primary"
                className="signup-btn"
                htmlType="submit"
                disabled={!formFilled}
                style={
                  formFilled
                    ? { backgroundColor: "#0faf51" }
                    : { backgroundColor: "#e5e5e5" }
                }
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </main>
    </section>
  );
};

export default SignUpComponent;
