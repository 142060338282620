import Dexie from "dexie";

class IndexDbService extends Dexie {
  static dbName = "workflow-db";

  constructor() {
    super(IndexDbService.dbName);
    // register client

    this.version(1).stores({
      pdfConfig: "", // Primary key and indexed props
    });
  }
}

export const localDb = new IndexDbService();
