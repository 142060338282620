import { AuthService } from "../components/auth/services/auth.service";
import { BASE_URL } from "../constant/constants";

export const AppService = {
  getNotifications: async (dispatch) => {
    const result = await (
      await fetch(`${BASE_URL}/notification/list`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${AuthService.getToken()}`,
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  updateNotification: async (notificationId, payload) => {
    const result = await (
      await fetch(`${BASE_URL}/notification/update/${notificationId}`, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${AuthService.getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
    ).json();

    return result;
  },
};
