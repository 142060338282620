import { MoreOutlined } from "@ant-design/icons";
import { Badge, Button, message, Space, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import AvatarMenu from "../../common/avatar-menu";
import { tokenValidator } from "../../common/token-validator";
import { LOCALSTORAGE_KEYS } from "../../constant/constants";
import { localDb } from "../../services/dexie.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { getOneForm, updateForm } from "../../store/dashboard.effect";
import { publish } from "../../store/design.effect";
import { designActions } from "../../store/design.reducer";
import PublishComponent from "./publish-modal";
import WorkFlowForm from "./workflow-form";

const { Title, Paragraph } = Typography;

const DesignComponent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [publishLoading, setPublishLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const showCollapse = useRef(false);
  const editorRef = useRef(null);
  let recipients = useSelector((state) => state.design.recipients);
  let widgetCount = useSelector((state) => state.design.widgetCount);
  let isMenuCollapsed = useSelector((state) => state.design.isMenuCollapsed);

  // get single form from server
  const getForm = async (formId) => {
    const formResult = await getOneForm(formId)();
    tokenValidator(formResult, navigate);
    if (formResult.statusCode === 200 && formResult.data) {
      dispatch(designActions.setDesignConfig(formResult.data.config));
      const { name, id, isPublished, config, type } = formResult.data;
      setSelectedForm({ name, id, isPublished, formType: type });
      editorRef.current = config;
    }
  };

  useEffect(() => {
    const formId = params?.designId;
    if (formId) {
      getForm(formId);
    }

    return () => {
      // clear internal states
      recipients = [];
      widgetCount = 0;
    };
  }, [params?.designId]);

  useEffect(() => {
    showCollapse.current = window.screen.width <= 560;
  }, []);

  // save draft button click handler
  const handleSaveDraft = async (event) => {
    event?.stopPropagation();
    setDraftLoading(true);
    const form = {
      name: selectedForm.name,
      config: JSON.stringify(editorRef.current),
      isDraft: true,
      lastDrafted: moment().utc(true).toDate(),
    };

    const result = await updateForm(form, selectedForm.id)();
    setDraftLoading(false);
    if (result?.statusCode === 200) {
      message.success("Draft saved successfully.");
    } else {
      message.error("Error saving draft.");
    }
    localDb.pdfConfig.clear();
  };

  // publish form button handler
  const handlePublish = () => {
    setIsOpen(true);
  };

  // method which updates form name.
  const formHeaderChange = async (change) => {
    if (change && change !== selectedForm?.name) {
      const form = { name: change };
      const result = await updateForm(form, selectedForm.id)();
      if (result?.statusCode === 200) {
        setSelectedForm({ ...selectedForm, name: change });
        message.success("Form updated successfully.");
      }
    }
  };

  const handleLogoClick = () => {
    navigate("/forms", { replace: true });
  };

  const publishForm = async () => {
    setPublishLoading(true);

    const publishPayload = {
      config: JSON.stringify(editorRef.current),
      recipients: recipients?.map((tag) => {
        const { id, signs, color, ...rest } = tag;
        const signsJoined = signs.join(",");
        return { ...rest, signKeys: signsJoined };
      }),
    };

    const result = await publish(publishPayload, selectedForm?.id)();
    tokenValidator(result, navigate);
    setPublishLoading(false);
    if (result?.statusCode === 200) {
      message.success("Form published successfully.");
      setSelectedForm((state) => {
        state.isPublished = true;
        return state;
      });
    } else {
      message.error("Error publishing form");
    }
    localDb.pdfConfig.clear();
    LocalStorageService.removeItem(LOCALSTORAGE_KEYS.recipients);
  };

  const handleEditorCollapse = (e) => {
    dispatch(designActions.setToolsCollapse(!isMenuCollapsed));
  };

  return (
    <div className="design-container">
      <header className="header-wrapper">
        <div className="logo-container" onClick={handleLogoClick}>
          <img src="/assets/logo.png" alt="WFC" />
        </div>
        <div className="dash-nav">
          {selectedForm?.isPublished ? (
            <Paragraph className="no-margin already-publish">
              This document has been already published.
            </Paragraph>
          ) : (
            <Space size={"middle"}>
              <Button onClick={handleSaveDraft} loading={draftLoading}>
                Save as Draft
              </Button>
              <Badge count={recipients.length}>
                <Button
                  type="primary"
                  className="new-btn"
                  onClick={handlePublish}
                >
                  Add Recipients
                </Button>
              </Badge>
              <Button
                type="primary"
                className="new-btn"
                onClick={publishForm}
                loading={publishLoading}
                disabled={!recipients.length || !widgetCount}
              >
                Publish Form
              </Button>
              <AvatarMenu />
            </Space>
          )}
        </div>
      </header>
      <div className="editor-container">
        <div className="form-header">
          <Title
            className="no-margin"
            editable={{ onChange: formHeaderChange }}
            level={3}
          >
            {selectedForm?.name}
          </Title>
          {showCollapse.current ? (
            <Button
              type="default"
              icon={<MoreOutlined />}
              onClick={(e) => handleEditorCollapse(e)}
            />
          ) : null}
        </div>
        {selectedForm ? (
          <WorkFlowForm
            formId={params?.designId}
            formType={selectedForm?.formType}
            editorRef={editorRef}
            isPublished={selectedForm?.isPublished}
            saveDraft={handleSaveDraft}
          />
        ) : null}
      </div>
      <PublishComponent
        modalState={{ isOpen, setIsOpen }}
        setSelectedForm={setSelectedForm}
        formDetails={{
          name: selectedForm?.name,
          id: selectedForm?.id,
          config: editorRef.current,
          type: selectedForm?.formType,
        }}
      />
    </div>
  );
};

export default DesignComponent;
