import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Dropdown, Typography } from "antd";
import { useNavigate } from "react-router";
import { AuthService } from "../components/auth/services/auth.service";
import { LOCALSTORAGE_KEYS } from "../constant/constants";
import { tokenDetailExtractor } from "../helper/token-detail-extractor";
import { LocalStorageService } from "../services/localstorage.service";

const { Title, Paragraph } = Typography;

const AvatarMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthService.logout(navigate);
  };

  const mainMenu = () => {
    const token = LocalStorageService.getFromLocalStorage(
      LOCALSTORAGE_KEYS.token
    );
    const userData = tokenDetailExtractor(token);
    return (
      <div className="avatar-menu-container">
        <Avatar
          className="avatar-icon"
          size={"large"}
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
          icon={<UserOutlined />}
          onClick={(e) => e.preventDefault()}
        />
        <div className="avatar-greet">
          <Title level={4} className="no-margin greet-title">
            Hi,
          </Title>
          <Paragraph className="greet-name">
            {!userData?.firstName
              ? userData?.email
              : `${userData?.firstName} ${userData?.lastName}`}
          </Paragraph>
        </div>
        <Divider className="greet-divider" />
        <Button className="greet-logout" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    );
  };

  return (
    <Dropdown trigger={["click"]} dropdownRender={mainMenu}>
      <Avatar
        style={{
          color: "#f56a00",
          backgroundColor: "#fde3cf",
        }}
        icon={<UserOutlined />}
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  );
};

export default AvatarMenu;
