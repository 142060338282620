import {
  DeleteFilled,
  EditFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Empty,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import * as uniqueNames from "project-name-generator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AvatarMenu from "../../common/avatar-menu";
import ConfirmDialog from "../../common/confirm-dialog";
import NotificationComponent from "../../common/notification";
import { tokenValidator } from "../../common/token-validator";
import { localDb } from "../../services/dexie.service";
import {
  createForm,
  deleteForm,
  getAllForms,
} from "../../store/dashboard.effect";
import FlowDialogComponent from "./flow-dialog";

const { Paragraph, Title } = Typography;

const FormsComponent = () => {
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [allForms, setAllForms] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // get all forms from server
  const fetchAllForms = async () => {
    const allFormsRes = await getAllForms()();
    tokenValidator(allFormsRes, navigate);
    // clear the localstorage for all pdf pages
    localDb.pdfConfig.clear();
    if (allFormsRes?.statusCode === 200) {
      const formattedForms = allFormsRes.data.map((form) => {
        if (form.isActive) {
          form.badge = "success";
          form.status = "Active";
          if (form.isDraft) {
            form.badge = "processing";
            form.status = "Draft";
          }
        } else {
          form.badge = "error";
          form.status = "Inactive";
        }
        return form;
      });
      setAllForms(formattedForms);
    }
  };

  useEffect(() => {
    // fetch all forms of current user.
    if (!allForms) {
      fetchAllForms();
    }
  }, [allForms]);

  const newForm = async (formType) => {
    // make api call to create a form and generate its id.
    const newForm = { name: uniqueNames().dashed, type: formType };
    const newFormRes = await createForm(newForm)();
    tokenValidator(newFormRes, navigate);
    if (newFormRes?.statusCode === 200) {
      navigate(`/design/${newFormRes.data.id}`, { replace: true });
      setSelectedForm(newFormRes.data);
    } else {
      message.error("Error create a new form");
    }
    setIsOpen(false);
  };

  const createNewForm = async () => {
    setIsOpen(true);
  };

  // handle form deletion
  const handleFormDeletion = async () => {
    const deleteRes = await deleteForm(selectedForm.id)();
    tokenValidator(deleteRes, navigate);
    if (deleteRes?.statusCode === 200) {
      setSelectedForm(null);
      setAllForms(null);
    } else {
      message.error("Removing form failed");
    }
    setConfirmModal(false);
  };

  const onFormDelete = (form) => {
    setSelectedForm(form);
    setConfirmModal(true);
  };

  const handleFormEdit = (form) => {
    setSelectedForm(null);
    navigate(`/design/${form.id}`, { replace: true });
  };

  const handleFormStatus = (form) => {
    setSelectedForm(null);
    navigate(`/status/${form.id}`, { replace: true });
  };

  const StatusButton = (form) => (
    <Tooltip title="View Status">
      <InfoCircleOutlined key="status" onClick={() => handleFormStatus(form)} />
    </Tooltip>
  );

  const EditButton = (form) => (
    <Tooltip title="Edit Form">
      <EditFilled key="edit" onClick={() => handleFormEdit(form)} />
    </Tooltip>
  );

  const DeleteButton = (form) => (
    <Tooltip title="Delete Form">
      <DeleteFilled key="delete" onClick={() => onFormDelete(form)} />
    </Tooltip>
  );

  const emptyDesc = (
    <>
      <Title strong level={4}>
        No forms created
      </Title>
      <Paragraph style={{ color: "grey" }}>
        Click on &nbsp;
        <q>
          <b>Create Form</b>
        </q>
        &nbsp; button to add one.
      </Paragraph>
    </>
  );

  return (
    <div className="dashboard-container">
      <header className="header-wrapper">
        <div className="logo-container">
          <img src="/assets/logo.png" alt="WFC" />
        </div>
        <div className="dash-nav">
          <Space size="middle">
            <Button type="primary" className="new-btn" onClick={createNewForm}>
              Create Form
            </Button>
            <AvatarMenu />
            <NotificationComponent />
          </Space>
        </div>
      </header>
      <div
        className={
          allForms?.length
            ? "dashboard-content"
            : "dashboard-content center-all"
        }
      >
        {allForms?.length ? (
          <Row gutter={[16, 16]}>
            {allForms?.map((form) => {
              return (
                <Col span={4} key={form.id}>
                  <Card
                    title={form.name}
                    className="card-body"
                    actions={[
                      StatusButton(form),
                      EditButton(form),
                      DeleteButton(form),
                    ]}
                  >
                    <Space align="center">
                      <Badge className="custom-badge" status={form.badge} />
                      <Paragraph className="no-margin" strong={true}>
                        {form.status}
                      </Paragraph>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Empty description={emptyDesc} />
        )}
      </div>
      <ConfirmDialog
        title="Are you sure you want to delete?"
        body={selectedForm}
        isOpen={confirmModal}
        setModalOpen={setConfirmModal}
        onSuccess={handleFormDeletion}
      />
      <FlowDialogComponent
        modalState={{ isOpen, setIsOpen }}
        newForm={newForm}
      />
    </div>
  );
};

export default FormsComponent;
