import { LOCALSTORAGE_KEYS } from "../constant/constants";
import { LocalStorageService } from "../services/localstorage.service";
import { tokenDetailExtractor } from "./token-detail-extractor";

export const generateUniqueLightColors = (entriesLength) => {
  const saturation = 90; // You can adjust this value to control color saturation
  const lightness = 80; // You can adjust this value to control color lightness
  const step = 360 / entriesLength; // Calculate the hue step

  const colors = [];

  for (let i = 0; i < entriesLength; i++) {
    const hue = i * step;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    colors.push(color);
  }

  return colors;
};

export const loggedInUser = () => {
  // get token
  const _token = LocalStorageService.getFromLocalStorage(
    LOCALSTORAGE_KEYS.token
  );
  const loggedInUser = tokenDetailExtractor(_token);
  return loggedInUser;
};
