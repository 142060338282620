import moment from 'moment';
import { LOCALSTORAGE_KEYS } from '../constant/constants';
import { tokenDetailExtractor } from '../helper/token-detail-extractor';
import { LocalStorageService } from '../services/localstorage.service';

export const tokenValidator = (res, navigate) => {
    if (res?.statusCode === 401) {
        LocalStorageService.removeItem(LOCALSTORAGE_KEYS.token);
        navigate('/', { replace: true });
    }
}

export const isTokenValid = (token) => {
    const tokenDetail = tokenDetailExtractor(token);
    if (!tokenDetail) {
        return false;
    }
    const expiry = moment.unix(tokenDetail.exp);
    return moment().isBefore(expiry);
}