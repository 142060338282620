import { Button, message, Result } from "antd";
import React, { useLayoutEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCALSTORAGE_KEYS, OTP_CIRCLES } from "../../constant/constants";
import { LocalStorageService } from "../../services/localstorage.service";
import { appActions } from "../../store/app.reducer";
import { verify } from "../../store/user.effect";

const SignUpSuccessComponent = () => {
  const otpRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [circlesCompleted, setCirclesCompleted] = useState(false);
  const [circles, setCircles] = useState(OTP_CIRCLES);
  const codeRegex = /([A-Za-z0-9])+/g;

  useLayoutEffect(() => {
    otpRef.current.children[0].children[0].focus();
    return () => {
      // reset circles
      resetCircles();
    };
  }, []);

  const resetCircles = () => {
    setCircles((state) => {
      return state.map((it) => {
        it.value = "";
        return it;
      });
    });
    setCirclesCompleted(false);
  };

  const circleChecker = () => {
    return circles.every((item) => item.value);
  };

  const handleOnKeyUp = (event) => {
    const emptyCircleIndex = circles.findIndex((item) => !item.value);
    if (
      event.code === "Backspace" ||
      event.key === "Backspace" ||
      event.code === "Delete" ||
      event.key === "Delete"
    ) {
      const newCircles = circles.map((circle, index) => {
        if (
          (emptyCircleIndex >= 0 && index === emptyCircleIndex - 1) ||
          index === circles.length - 1
        ) {
          circle.value = "";
        }
        return circle;
      });
      setCircles(newCircles);
      setCirclesCompleted(circleChecker());
    }
  };

  const onInpChange = (event) => {
    event.preventDefault();
    const emptyCircleIndex = circles.findIndex((item) => !item.value);
    if (
      event.nativeEvent.inputType === "insertText" &&
      codeRegex.test(event.nativeEvent.data)
    ) {
      const newCircles = circles.map((circle, index) => {
        if (index === emptyCircleIndex) {
          circle.value = event.nativeEvent.data;
        }
        return circle;
      });
      setCircles(newCircles);
      setCirclesCompleted(circleChecker());
    }
  };

  const verifyCode = () => {
    const code = circles.map((it) => it.value).join("");
    if (code.length === 4) {
      dispatch(appActions.setIsLoading(true));
      verify({ code }, location.state.verifyType)()
        .then((data) => {
          if (data.statusCode === 200) {
            LocalStorageService.setToLocalStorage(
              LOCALSTORAGE_KEYS.token,
              data.authToken
            );
            navigate("/dashboard", { replace: true });
          } else {
            message.error(`Error verifying user`);
            resetCircles();
          }
        })
        .finally(() => {
          dispatch(appActions.setIsLoading(false));
        });
    }
  };

  const navigateHome = () => {
    navigate("/", { replace: true });
  };

  const codeInput = (
    <div className="code-input-container" ref={otpRef}>
      {circles.map((item) => {
        return (
          <div
            key={item.id}
            className="code-inp"
            style={
              item.value
                ? { border: "3px solid #1677ff", backgroundColor: "#ececec" }
                : {}
            }
          >
            <ReactInputMask
              mask="*"
              maskChar={null}
              onChange={onInpChange}
              onKeyUp={handleOnKeyUp}
              value={item.value}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <section className="signup-wrapper">
      <header className="header-wrapper">
        <div className="logo-container" onClick={navigateHome}>
          <img src="assets/logo.png" alt="WFC" />
        </div>
      </header>
      <main className="signup-middle">
        <div className="signup-success-wrapper">
          <Result
            className="success-container"
            status="success"
            title="Great, your account is created. Please enter the code received on your registered email."
            extra={codeInput}
          />
          <Button
            className="verify-btn"
            style={
              circlesCompleted
                ? { backgroundColor: "#1677ff" }
                : { backgroundColor: "#e5e5e5" }
            }
            type="primary"
            key="Verify"
            disabled={!circlesCompleted}
            onClick={verifyCode}
          >
            Verify
          </Button>
        </div>
      </main>
    </section>
  );
};

export default SignUpSuccessComponent;
