import { BASE_URL } from "../../../constant/constants";
import { AuthService } from "../../auth/services/auth.service";

export const SignatureService = {
  addSignature: (payload) => {
    return fetch(`${BASE_URL}/signature/add`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },

  getCurrentSignature: () => {
    return fetch(`${BASE_URL}/signature/listCurrent`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
  },

  updateSignature: (payload, signId) => {
    return fetch(`${BASE_URL}/signature/update/${signId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },

  findUser: (payload) => {
    return fetch(`${BASE_URL}/user/get`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },
};
