import { Navigate, useLocation } from "react-router-dom";
import { LOCALSTORAGE_KEYS, USER_ACTIONS } from "../constant/constants";
import { LocalStorageService } from "../services/localstorage.service";
import { isTokenValid } from "./token-validator";

const restrictedRoutes = ['/forgotStatus', '/signUpSuccess'];
export const LoginGuard = ({ children }) => {
    const token = LocalStorageService.getFromLocalStorage(LOCALSTORAGE_KEYS.token);
    const location = useLocation();
    if (!isTokenValid(token)) {
        LocalStorageService.removeItem(LOCALSTORAGE_KEYS.token);
        if (!restrictedRoutes.includes(location.pathname) || location.state?.action === USER_ACTIONS.internal) {
            return children;
        }
        return <Navigate to="/" state={{ from: location }} replace />
    } else {
        return <Navigate to="/dashboard" state={{ from: location }} replace />
    }

}
