import {
  Badge,
  Button,
  Empty,
  Input,
  Modal,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  EMAIL_REGEX,
  FLOW_TYPE,
  LOCALSTORAGE_KEYS,
  ROLES,
  ROLE_COLORS,
} from "../../constant/constants";
import { generateUniqueLightColors } from "../../helper/util";
import { LocalStorageService } from "../../services/localstorage.service";
import { getRoles } from "../../store/design.effect";
import { designActions } from "../../store/design.reducer";

const { Paragraph } = Typography;
const { Option } = Select;

const PublishComponent = ({ modalState, formDetails }) => {
  const dispatch = useDispatch();
  const [publishInp, setPublishInp] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [rolesError, setRolesError] = useState(null);
  const [emailTags, setEmailTags] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [role, setRole] = useState([]);
  const [showSign, setShowSign] = useState(false);
  const roleRef = useRef(null);

  const rolesRemote = useSelector((state) => state.design.roles);

  useEffect(() => {
    if (!rolesRemote.length) {
      getRoles(dispatch);
    }

    calculateDisableState();
  }, [rolesRemote, publishInp, role, emailTags]);

  useEffect(() => {
    // find any persistent recipients in localstorage, then load them.
    const savedRecipients = LocalStorageService.getFromLocalStorage(
      LOCALSTORAGE_KEYS.recipients
    );

    if (savedRecipients?.length) {
      setEmailTags(savedRecipients);
      dispatch(designActions.setRecipients(savedRecipients));
    }
  }, []);

  const afterModalClose = () => {
    setPublishInp(null);
    setRole([]);
    setEmailError(null);
    setRolesError(null);
    setShowSign(false);
    // set emails added, to redux and access on pdfViewer component.
    dispatch(designActions.setRecipients(emailTags));
    LocalStorageService.setToLocalStorage(
      LOCALSTORAGE_KEYS.recipients,
      emailTags
    );
  };

  const handlePublishCancel = () => {
    modalState.setIsOpen(false);
  };

  const removeEmail = (event, selectedTag) => {
    event.preventDefault();
    setEmailTags((tags) => tags.filter((tag) => tag.id !== selectedTag.id));
  };

  const calculateDisableState = () => {
    // setDisablePublish(!!publishInp);
    const approverExist = emailTags.filter(
      (recipient) => recipient.role.role === ROLES.approver
    ).length;

    if (!approverExist || !emailTags.length) {
      // setDisablePublish(true);
    }
  };

  const handleEmailChange = (changes) => {
    setEmailError(null);
    setPublishInp(changes.target.value);
  };

  const addEmail = (e) => {
    e.preventDefault();
    if (publishInp) {
      if (!EMAIL_REGEX.test(publishInp)) {
        setEmailError("Please enter a valid email");
        return;
      }

      if (role) {
        const matchingRole = rolesRemote.find((it) => it.role === role);
        const signsMapped = signatures.map((sign) => sign.value);
        let updatedEmails = [
          ...emailTags,
          {
            email: publishInp.toLowerCase(),
            id: uuid(),
            role: matchingRole,
            signs: signsMapped,
          },
        ];
        const colors = generateUniqueLightColors(updatedEmails.length);
        updatedEmails = updatedEmails.map((it, i) => {
          return {
            ...it,
            color: it.color || colors[i],
          };
        });
        setEmailTags(updatedEmails);
      } else {
        setRolesError("Please select at least one role.");
      }

      setPublishInp(null);
      setRole([]);
      setSignatures([]);
      setShowSign(false);
    }
  };

  const addSignatures = (options) => {
    const mappedSigns = options.map((it) => {
      return {
        value: it,
        label: it,
      };
    });
    setSignatures(mappedSigns);
  };

  const handleRoleChange = (selectedRole) => {
    // reset roles error
    setRolesError(null);
    setRole(selectedRole);

    // find the last added role from remote roles.
    const approver = rolesRemote
      .filter((it) => selectedRole === it.role)
      .filter((it) => it.role === ROLES.approver);
    setShowSign(!!approver.length && formDetails.type === FLOW_TYPE.design);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const emailDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorder(
      emailTags,
      result.source.index,
      result.destination.index
    );

    setEmailTags(reordered);
  };

  const footer = [
    <Button key={0} onClick={handlePublishCancel}>
      Cancel
    </Button>,
    <Button key={2} type="primary" disabled={!publishInp} onClick={addEmail}>
      Add Recipient
    </Button>,
  ];

  return (
    <Modal
      title={`Publish ${formDetails.name}`}
      centered
      open={modalState.isOpen}
      onCancel={handlePublishCancel}
      afterClose={afterModalClose}
      maskClosable={false}
      footer={footer}
    >
      <div className="publish-content">
        <div
          className="empty-publish-container"
          style={
            !emailTags?.length
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          {emailTags?.length ? (
            <DragDropContext onDragEnd={emailDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="center-all"
                    style={{
                      width: "100%",
                      height: 248,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {emailTags.map((it, index) => (
                      <Draggable key={it.id} draggableId={it.id} index={index}>
                        {(provided, snapshot) => (
                          <div className="order-wrap">
                            <div className="order-action">{index + 1}</div>
                            <Tag
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="email-tag"
                              key={it.id}
                              closable={true}
                              onClose={(e) => removeEmail(e, it)}
                            >
                              {it.email}
                              &nbsp;
                              {
                                <Tooltip title={it.role.role}>
                                  <Badge
                                    className="role-tag"
                                    key={it.role.id}
                                    color={
                                      ROLE_COLORS[it.role.role] ||
                                      it.role?.color
                                    }
                                    title={it.role.role}
                                  />
                                </Tooltip>
                              }
                            </Tag>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Empty
              description="You don't have any emails added yet."
              className="empty-publish"
            />
          )}
        </div>
        <div className="email-content">
          <Space direction={"vertical"} style={{ width: "100%" }}>
            <Paragraph
              className="email-error"
              style={{ color: "#1677ff", margin: 0 }}
            >
              Enter an email
            </Paragraph>
            <Space className="email-inp-container" size={"middle"}>
              <Input
                type="email"
                placeholder="Ex: john@company.com"
                onChange={handleEmailChange}
                maxLength={64}
                onPressEnter={addEmail}
                value={publishInp}
                status={emailError ? "error" : null}
              />
            </Space>
            <Paragraph
              style={
                emailError
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="email-error"
            >
              {emailError}
            </Paragraph>
          </Space>

          <Space direction={"vertical"} style={{ width: "100%" }}>
            <Paragraph
              className="email-error"
              style={{ color: "#1677ff", margin: 0 }}
            >
              Choose a role
            </Paragraph>
            <Select
              placeholder="Ex: APPROVER"
              onChange={handleRoleChange}
              style={{ width: "100%" }}
              className="email-role"
              value={role}
              ref={roleRef}
            >
              {
                // for some reason antd select does not allow developers to add an object of any kind as value.
                rolesRemote
                  .filter((role) => role.role !== ROLES.creator)
                  .map((role) => (
                    <Option key={role.id} value={role.role} label={role.label}>
                      {role.label}
                    </Option>
                  ))
              }
            </Select>
            <Paragraph
              style={
                rolesError
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="email-error"
            >
              {rolesError}
            </Paragraph>
          </Space>
        </div>
        {showSign ? (
          <div className="signature-content">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add Signatures. Ex: SIG:1"
              onChange={addSignatures}
              options={signatures}
              value={signatures}
            />
          </div>
        ) : null}
        <Space className="info-bar">
          {rolesRemote?.map((role, i) => {
            return (
              <Badge
                key={role.id}
                color={ROLE_COLORS[role.role]}
                text={role.label}
              />
            );
          })}
        </Space>
      </div>
    </Modal>
  );
};

export default PublishComponent;
