import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Button, Empty, Popover, Typography } from "antd";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { BASE_URL, LOCALSTORAGE_KEYS } from "../constant/constants";
import { AppService } from "../services/app.service";
import { LocalStorageService } from "../services/localstorage.service";
import { tokenDetailExtractor } from "../helper/token-detail-extractor";

const { Paragraph } = Typography;

const NotificationComponent = () => {
  const socket = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const removeNotification = (notificationId) => {
    const filtered = notifications.filter((it) => it.id !== notificationId);
    setNotifications(filtered);
  };

  const closeNoti = (selectedNoti, event) => {
    event.stopPropagation();
    updateNotification(selectedNoti.id);
    removeNotification(selectedNoti.id);
  };

  const updateNotification = async (notificationId) => {
    const payload = {
      isRead: true,
    };
    const result = await AppService.updateNotification(notificationId, payload);
  };

  const loadLink = (event, notification) => {
    event.nativeEvent.stopPropagation();
    window.open(notification.extras.link, "_blank", "noreferrer");
    updateNotification(notification.id);
    removeNotification(notification.id);
    // update the notification to be read already.
  };

  const sortNotifications = (notifications) => {
    // takes notifications and returns sorted notifications.
    return notifications?.sort((a, b) => {
      if (moment(a.triggeredOn).isAfter(moment(b.triggeredOn))) {
        return -1;
      } else if (moment(a.triggeredOn).isBefore(moment(b.triggeredOn))) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const getNotifications = async () => {
    const result = await AppService.getNotifications();
    if (result.statusCode === 200) {
      const sorted = sortNotifications(result.data);
      setNotifications(sorted);
    }
  };

  const initializeSocket = () => {
    const token = LocalStorageService.getFromLocalStorage(
      LOCALSTORAGE_KEYS.token
    );

    socket.current = io(BASE_URL, {
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    })

    socket.current.on("connect", (data) => {
      // handle socket connected state
    });

    socket.current.on("notify", ({ data }) => {
      
      // get logged in user email
      const loggedInUser = tokenDetailExtractor(token);

      // get notification recipient email 
      const recipientEmail = data.recipient.email;

      // check if the notification is for this user by matching emails
      if(loggedInUser.email === recipientEmail) {
        notifications.push(data);
        const sorted = sortNotifications(notifications);
        setNotifications(sorted);
      }
    });

    socket.current.on("disconnect", () => {
      // handle socket disconnection
    });
  };

  useEffect(() => {
    if (!notifications?.length) {
      getNotifications();
    }
  }, []);

  useEffect(() => {
    if (!socket.current) {
      initializeSocket();
    }

    return () => {
      socket.current.disconnect(); // Clean up the socket connection when component unmounts
    };
  }, []);

  return (
    <Badge dot={!!notifications?.length}>
      <div className="notifications-container">
        <Popover
          onClick={() => getNotifications()}
          trigger="click"
          placement="bottomRight"
          title={
            <div className="noti-title">
              <span>Notifications</span>
              <span>+{notifications.length}</span>
            </div>
          }
          content={
            <div
              className="notifications-wrap"
              style={
                notifications?.length
                  ? { display: "block" }
                  : { display: "flex" }
              }
            >
              {notifications?.length ? (
                notifications.map((it, index) => {
                  return (
                    <div key={index} className="noti-list">
                      <div className="noti-header">
                        <Paragraph strong className="noti-title">
                          {it.text}
                        </Paragraph>
                        <CloseOutlined
                          style={{
                            cursor: "pointer",
                            color: "grey",
                            marginTop: 5,
                          }}
                          onClick={(e) => closeNoti(it, e)}
                        />
                      </div>
                      <Paragraph italic className="noti-desc">
                        {it.description}
                      </Paragraph>
                      <div className="noti-action">
                        <Button size={"small"} onClick={(e) => loadLink(e, it)}>
                          {it.extras.action}
                        </Button>
                        <Paragraph italic style={{ fontSize: 10 }}>
                          {moment(it.triggeredOn)
                            .utc(false)
                            .format("DD/MM/YY hh:mm A")}
                        </Paragraph>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty
                  imageStyle={{
                    height: 50,
                  }}
                  description={
                    <span style={{ fontSize: 12 }}>
                      You don't have any notifications.
                    </span>
                  }
                />
              )}
            </div>
          }
          arrow
        >
          <BellOutlined style={{ fontSize: 20, color: "#1677ff" }} />
        </Popover>
      </div>
    </Badge>
  );
};

export default NotificationComponent;
