import { DesignService } from "../components/workflow-design/services/design.service";
import { designActions } from "./design.reducer";

export const publish = (payload, formId) => {
  return async () => {
    try {
      return await (await DesignService.publishForm(payload, formId)).json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const getRoles = async (dispatch) => {
  try {
    const { statusCode, data } = await (await DesignService.getRoles()).json();
    if (statusCode === 200) {
      dispatch(designActions.setRoles(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadWorkflowImage = (payload) => {
  return async () => {
    try {
      return await (await DesignService.uploadWorkflowLogo(payload)).json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const removeWorkflowImage = (contentId) => {
  return async () => {
    try {
      return await (await DesignService.removeWorkflowImage(contentId)).json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const getUsers = (payload) => {
  return async () => {
    try {
      return await (await DesignService.fetchUsersByEmail(payload)).json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const getSignsByEmail = (payload) => {
  return async () => {
    try {
      return await (await DesignService.fetchSignaturesByEmail(payload)).json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};
