import { Empty, Steps, Typography } from "antd";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, ROLE_STATUS } from "../../constant/constants";
import { getOneForm } from "../../store/dashboard.effect";
import { DesignService } from "./services/design.service";

const { Paragraph, Text, Title } = Typography;

const FormStatusComponent = ({ smallView, from }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [form, setForm] = useState(null);
  const [recipients, setRecipients] = useState(null);
  const [progressStep, setProgressStep] = useState({ index: 0, percent: 0 });
  const [flow, setFlow] = useState(null);

  const getForm = async (formId) => {
    const { data, statusCode } = await getOneForm(formId)();
    if (statusCode === 200 && data) {
      setForm(data);
    }
    // TODO: show failure screen if form is not able to fetch
  };

  const getRecipients = async (workflowId) => {
    const result = await (
      await DesignService.getRecipientsByWorkflow(workflowId)
    ).json();
    result.data.sort((a, b) => a.order - b.order);
    setRecipients(result.data);
  };

  const setFlowOfStatus = (workflow) => {
    const newFlow = recipients
      .sort((a, b) => a.order - b.order)
      .map((recipient) => {
        const fullName = recipient.user.firstName
          ? `${recipient.user.firstName} ${recipient.user.lastName}`
          : recipient.user.email;
        const description = (
          <Paragraph>
            {recipient.user.firstName
              ? `${recipient.user.firstName} ${recipient.user.lastName}`
              : recipient.user.email}
          </Paragraph>
        );

        const subTitle = (
          <>
            {recipient.actedOn ? (
              <Paragraph className="status-subtitle no-margin">
                {moment(recipient.actedOn)
                  .utc(false)
                  .format("Do MMM YYYY hh:mm A")}
              </Paragraph>
            ) : null}
          </>
        );

        if (recipient.role.role === ROLES.creator) {
          const createdAtFormatted = (
            <Paragraph className="status-subtitle no-margin">
              {moment(workflow.createdAt).format("Do MMM YYYY hh:mm A")}
            </Paragraph>
          );

          return {
            title: "Created by",
            subTitle: createdAtFormatted,
            status: "finish",
            description,
          };
        } else if (recipient.role.role === ROLES.gatekeeper) {
          let status = "wait";
          let title = "Waiting to Review by";
          if (recipient.status === ROLE_STATUS.rejected) {
            status = "error";
            title = "Rejected";
          } else if (recipient.status === ROLE_STATUS.approved) {
            status = "finish";
            title = "Reviewed";
          }

          const description = (
            <>
              {recipient?.remarks ? (
                <>
                  <Paragraph>{fullName}</Paragraph>
                  <Paragraph
                    italic
                    className="no-margin"
                    style={{
                      fontSize: 10,
                      border: "1px dashed #D22B2B",
                      backgroundColor: "rgba(210, 43, 43, 0.05)",
                      padding: "5px 10px 5px 10px",
                      borderRadius: 10,
                    }}
                  >
                    Reason: {recipient.remarks}
                  </Paragraph>
                </>
              ) : (
                <Paragraph>{fullName}</Paragraph>
              )}
            </>
          );

          return {
            title,
            subTitle,
            status,
            description,
          };
        } else if (recipient.role.role === ROLES.approver) {
          let status = "wait";
          let title = "Waiting to Approve by";
          const description = (
            <>
              {recipient?.remarks ? (
                <>
                  <Paragraph>{fullName}</Paragraph>
                  <Paragraph
                    italic
                    className="no-margin"
                    style={{
                      fontSize: 10,
                      border: "1px dashed #D22B2B",
                      backgroundColor: "rgba(210, 43, 43, 0.05)",
                      padding: "5px 10px 5px 10px",
                      borderRadius: 10,
                    }}
                  >
                    Reason: {recipient.remarks}
                  </Paragraph>
                </>
              ) : (
                <Paragraph>{fullName}</Paragraph>
              )}
            </>
          );
          if (recipient.status === ROLE_STATUS.rejected) {
            status = "error";
            title = "Rejected";
          } else if (recipient.status === ROLE_STATUS.approved) {
            status = "finish";
            title = "Approved";
          }

          return {
            title,
            subTitle,
            status,
            description,
          };
        } else {
          let status = "wait";
          let title = "Waiting to Receive by";

          if (recipient.status === ROLE_STATUS.rejected) {
            status = "error";
            title = "failed";
          } else if (recipient.status === ROLE_STATUS.received) {
            status = "finish";
            title = "Completed";
          }
          return {
            title,
            subTitle,
            status,
            description,
          };
        }
      });
    setFlow(newFlow);
  };

  useEffect(() => {
    // if formId exist in url then make find one and get workflow
    if (params?.formId && !form) {
      getForm(params?.formId);
    }
  }, [params?.formId, form]);

  useEffect(() => {
    if (form?.workflow) {
      const { workflow } = form;
      getRecipients(workflow.id);
    }
  }, [form]);

  useEffect(() => {
    if (recipients && form?.workflow) {
      const { workflow } = form;
      setFlowOfStatus(workflow);
    }
  }, [recipients, form]);

  const handleLogoClick = () => {
    navigate("/forms", { replace: true });
  };

  const emptyDesc = (
    <div className="workflow-status-empty">
      <Title strong level={4}>
        No Workflow Exist
      </Title>
      <Paragraph className="para-style no-margin">
        You don't have any workflow associated with this form,
      </Paragraph>
      <Paragraph className="para-style">
        Please publish a form first to see it's status.
      </Paragraph>
    </div>
  );

  return (
    <div className="dashboard-container">
      {from === "doc" ? null : (
        <header className="header-wrapper">
          <div className="logo-container" onClick={handleLogoClick}>
            <img src="/assets/logo.png" alt="WFC" />
          </div>
        </header>
      )}

      <div
        className={
          form?.workflow
            ? "dashboard-content form-status-container"
            : "dashboard-content form-status-container center-all"
        }
      >
        {form?.workflow ? (
          <>
            <Paragraph italic>
              Showing status of <Text strong>{form?.name}</Text>
            </Paragraph>
            <Steps
              direction={!!smallView ? "horizontal" : "vertical"}
              labelPlacement="vertical"
              size={!!smallView ? "small" : "default"}
              current={progressStep.index}
              percent={progressStep.percent}
              items={flow}
            />
          </>
        ) : (
          <Empty description={emptyDesc} />
        )}
      </div>
    </div>
  );
};

export default FormStatusComponent;
