import { BASE_URL } from "../../../constant/constants";
import { AuthService } from "../../auth/services/auth.service";

export const WorkflowService = {
  getWorkflow: (workflowId) => {
    return fetch(`${BASE_URL}/workflow/get/${workflowId}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
  },

  updateWorkflow: (workflowId, payload) => {
    return fetch(`${BASE_URL}/workflow/update/${workflowId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },

  uploadSignature: (payload) => {
    const formData = new FormData();
    for (const name in payload) {
      formData.append(name, payload[name]);
    }
    return fetch(`${BASE_URL}/signature/uploadSign`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
      body: formData,
    });
  },

  listSignature: () => {
    return fetch(`${BASE_URL}/signature/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
  },

  removeSignature: (signId) => {
    return fetch(`${BASE_URL}/signature/remove/${signId}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
  },

  exportAsPdf: (workflowId) => {
    return fetch(`${BASE_URL}/workflow/exportPdf/${workflowId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/pdf",
        authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
  },

  updateRecipient: (recipientId, formId, payload) => {
    return fetch(`${BASE_URL}/recipient/update/${recipientId}/${formId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },
};
