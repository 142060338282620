import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
const PageNotFoundComponent = () => {

    const navigate = useNavigate();

    const goHome = () => {
        navigate('/dashboard', { replace: true });
    }

    return (
        <div className="center-all" style={{ width: '100%', height: '100%' }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist or you don't have permission to view its contents."
                extra={
                    <Button type="primary" onClick={goHome}>Back Home</Button>
                }
            />
        </div>
    )
}

export default PageNotFoundComponent;