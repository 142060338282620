import {
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import EditorJS from "@editorjs/editorjs";
import {
  Button,
  Divider,
  Empty,
  Input,
  Modal,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AvatarMenu from "../../common/avatar-menu";
import ListPlugin from "../../common/plugins/inline-toolbars/list-inline";
import ParagraphPlugin from "../../common/plugins/paragraph-tool/paragraph-tool";
import TablePlugin from "../../common/plugins/table";
import { tokenValidator } from "../../common/token-validator";
import {
  BASE_URL,
  DIALOG_UI_CONTENT,
  FLOW_TYPE,
  LOCALSTORAGE_KEYS,
  PROCEED_BTN_CONFIG_MAP,
  ROLES,
  ROLE_STATUS,
  ROLE_STATUS_COLOR_MAP,
} from "../../constant/constants";
import { tokenDetailExtractor } from "../../helper/token-detail-extractor";
import { localDb } from "../../services/dexie.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { getOneForm } from "../../store/dashboard.effect";
import { designActions } from "../../store/design.reducer";
import { WorkflowService } from "../document/services/workflow.service";
import PdfViewerComponent from "../workflow-design/pdf-viewer";
import { DesignService } from "../workflow-design/services/design.service";
import FormStatusComponent from "../workflow-design/workflow-status";

const pdfJS = require("pdfjs-dist/build/pdf");
pdfJS.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.js";

const { Paragraph } = Typography;
const { TextArea } = Input;

const DocumentComponent = () => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const rejectRef = useRef(null);
  const [alreadyPublish, setAlreadyPublish] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const [render, setRender] = useState(1);
  const [selectedForm, setSelectedForm] = useState();
  const [signMode, setSignMode] = useState(true);
  const [signKeys, setSignKeys] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [approveLoader, setApproveLoader] = useState(false);
  const [proceedBtn, setProceedBtn] = useState({
    color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.pending],
    name: "---",
    disabled: false,
  });
  const [selectedWorkflow, setSelectedWorkflow] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    action: "",
  });
  const currentSignDetails = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [onlyView, setOnlyView] = useState(false);
  const isRejected = useRef(false);
  const [allPdf, setAllPdf] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [updatePage, setUpdatePage] = useState(null);
  const editorChange = useSelector((state) => state.design.editorChanged);
  const totalPages = useRef(0);
  const exportList = useRef([]);
  const [exportLoader, setExportLoader] = useState(false);
  const isStatusSmall = useRef(false);

  // get token
  const _token = LocalStorageService.getFromLocalStorage(
    LOCALSTORAGE_KEYS.token
  );

  const loggedInUser = tokenDetailExtractor(_token);

  // get single form from server
  const getForm = (formId) => {
    getOneForm(formId)()
      .then((data) => {
        tokenValidator(data, navigate);
        if (data.statusCode === 200 && data.data) {
          setSelectedForm(data.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const checkLinkExpiry = (expiry) => {
    if (expiry && moment().isAfter(moment(expiry))) {
      navigate("/pageNotFound", { replace: true });
    }
  };

  const getWorkflow = async (workflowId) => {
    const response = await (
      await WorkflowService.getWorkflow(workflowId)
    ).json();
    tokenValidator(response, navigate);
    if (!response?.data) {
      navigate("/pageNotFound", { replace: true });
      return;
    }

    setSelectedWorkflow(response.data);
    editorRef.current = response.data.config;

    // role from url param
    const roleFromUrl = window.atob(searchParams.get("role"));

    // set the config data to localstorage
    if (editorRef.current?.contents?.length) {
      const pdfStruct = editorRef.current.contents.map((pdf) => {
        return {
          id: pdf.id,
          config: pdf.data || [],
        };
      });
      await localDb.pdfConfig.put(pdfStruct, LOCALSTORAGE_KEYS.pdfData);
    }

    const rejectedStatus = response.data.recipients?.find(
      (recipient) => recipient.status === ROLE_STATUS.rejected
    );

    isRejected.current = !!rejectedStatus;

    const matchingRole = response.data.recipients?.find(
      (recipient) =>
        recipient.role.role === roleFromUrl &&
        loggedInUser?.email === recipient.user.email
    );

    const matchingLink = response.data.links.find(
      (link) => link.role.role === roleFromUrl
    );

    // check for link expiry
    checkLinkExpiry(matchingLink?.expiry);

    // set the signature keys if available
    // work on the editor part
    if (matchingRole?.signKeys) {
      const parsedSignKeys = matchingRole.signKeys
        .split(",")
        .filter((it) => !!it);
      //TODO: check the split values.
      setSignKeys(parsedSignKeys);
    }

    // check if url is tampered or current user doesn't have access to this page
    if (!roleFromUrl || !matchingRole) {
      navigate("/pageNotFound", { replace: true });
    }

    // role is creator but no export link means creator is viewing his document from history.
    if (matchingRole.role.role === ROLES.creator && !matchingLink?.export) {
      setOnlyView(true);
    }

    setCurrentRole(matchingRole);

    // disable the actions if no change in editor
    const status =
      matchingRole.role.role === ROLES.gatekeeper &&
      matchingRole.status !== ROLE_STATUS.pending
        ? "REVIEWED"
        : matchingLink.export
        ? ROLE_STATUS.pending
        : matchingRole?.status;

    // check if editor have changed then apply disabled state for button
    if (
      !editorChange &&
      status === ROLE_STATUS.pending &&
      matchingRole.role.role === ROLES.approver
    ) {
      setProceedBtn(PROCEED_BTN_CONFIG_MAP("disabled", undefined));
      setAlreadyPublish(false);
    } else {
      setProceedBtn(PROCEED_BTN_CONFIG_MAP(status, roleFromUrl));
      setAlreadyPublish(true);
    }
  };

  const getSignatures = async () => {
    const result = await (await WorkflowService.listSignature()).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      const formatFiles = result.data.map((it) => {
        const {
          id,
          createdAt,
          isDefault,
          signatureName,
          signatureFont,
          signatureInitials,
        } = it;

        if (it.isDefault) {
          currentSignDetails.current = {
            name: signatureName,
            initials: signatureInitials,
            font: signatureFont,
            serial: null,
            adoptionDate: it.adoptionDate,
            signId: it.id,
          };
        }

        return {
          url: `${BASE_URL}/signature/download/${id}`,
          uid: id,
          createdAt,
          isDefault,
          status: "done",
          isSelected: false,
        };
      });
      if (formatFiles.length) {
        setFileList(formatFiles);
      }
    }
  };

  const loadEditor = () => {
    // get editor data from approval config
    const editorData = selectedWorkflow?.config?.contents;
    const signs = selectedWorkflow?.recipients
      .map((recipient) => recipient.signKeys)
      .filter((i) => !!i)
      .flatMap((i) => (i.includes(",") ? i.split(",") : i));
    const signsToIterate = signs
      ?.map((sign) => {
        const recipientMatch = selectedWorkflow.recipients.find((recipient) =>
          recipient.signKeys.includes(sign)
        );

        if (
          ![ROLE_STATUS.approved, ROLE_STATUS.rejected].includes(
            recipientMatch.status
          )
        ) {
          return null;
        }

        const signObj = {
          placeholder: sign,
          style: "",
        };

        if (
          recipientMatch?.status === ROLE_STATUS.approved &&
          recipientMatch?.simpleApprove
        ) {
          signObj.style +=
            " transform: rotate(45deg); filter: grayscale(65%); background-image: url(/assets/approved.png);";
          // simple approve
        } else if (
          recipientMatch?.status === ROLE_STATUS.approved &&
          !recipientMatch?.simpleApprove
        ) {
          // advanced approve with sign
          signObj.style = ` background-image: url('${BASE_URL}/signature/download/${recipientMatch.sign}');`;
        } else if (recipientMatch?.status === ROLE_STATUS.rejected) {
          // rejected
          signObj.style +=
            " transform: rotate(45deg); filter: grayscale(65%); background-image: url(/assets/rejected.png);";
        }
        return signObj;
      })
      .filter((it) => it);

    new EditorJS({
      readOnly: true,
      holder: "editorjs",
      defaultBlock: "table",
      tools: {
        paragraph: {
          class: ParagraphPlugin,
          inlineToolbar: true,
        },
        list: {
          class: ListPlugin,
          inlineToolbar: true,
          config: {},
        },
        table: {
          class: TablePlugin,
          inlineToolbar: ["bold", "italic", "list"],
          config: {
            rows: 4,
            cols: 3,
            cellPadding: 4,
            border: {
              show: true,
              width: 1,
              color: "#dbdbe2",
              style: "solid",
              radius: 4,
              spacing: 0,
            },
          },
        },
      },
      data: editorData,
      onReady: () => {
        // recreate the signature section with images.
        //TODO: improve the code many block can be reused
        if (signsToIterate?.length) {
          // find the nodes containing text nodes that are in signs array
          signsToIterate.forEach((it, index) => {
            const xpath = `//node()[text()='${it.placeholder}']`;
            const signPlaceholders = window.document.evaluate(
              xpath,
              document,
              null,
              XPathResult.FIRST_ORDERED_NODE_TYPE,
              null
            ).singleNodeValue;
            const signElem = window.document.createElement("div");

            // no signature placeholder are found
            if (!signPlaceholders && !index) {
              message.error(
                "Please make sure signature placeholders are valid with publishing content."
              );
              return;
            }

            signElem.style = it.style;
            signElem.style.width = "100%";
            signElem.style.height = "60px";
            signElem.style.backgroundRepeat = "no-repeat";
            signElem.style.backgroundSize = "contain";
            signElem.style.backgroundPosition = "center";

            if (signPlaceholders) {
              signPlaceholders.textContent = null;
              signPlaceholders.appendChild(signElem);
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    // clear redirect url from localstorage
    LocalStorageService.removeItem(LOCALSTORAGE_KEYS.docPageAuth);
  }, [render]);

  useEffect(() => {
    isStatusSmall.current = window.screen.width <= 768;
  }, []);

  useEffect(() => {
    if (params?.formId && !selectedForm) {
      getForm(params.formId);
    }
  }, [selectedForm, params?.formId]);

  useEffect(() => {
    if (params?.workflowId && !selectedWorkflow) {
      getWorkflow(params.workflowId);
    }
  }, [selectedWorkflow, params?.workflowId]);

  useEffect(() => {
    if (!fileList.length) {
      getSignatures();
    }
  }, [fileList]);

  useEffect(() => {
    const roleFromUrl = window.atob(searchParams.get("role"));
    if (roleFromUrl === ROLES.approver) {
      if (!editorChange) {
        setProceedBtn(PROCEED_BTN_CONFIG_MAP("disabled", undefined));
        setAlreadyPublish(false);
      } else {
        // find matching role and apply button style.
        setProceedBtn(PROCEED_BTN_CONFIG_MAP(currentRole?.status, roleFromUrl));
        setAlreadyPublish(true);
      }
    }
  }, [editorChange]);

  useEffect(() => {
    if (params.formId) {
      getPdfs(params.formId);
    }
  }, [params?.formId]);

  useEffect(() => {
    if (allPdf.length) {
      const anySelectedPdf = allPdf.find((pdf) => pdf.isSelected);
      setSelectedPdf(anySelectedPdf);
    }
  }, [allPdf]);

  useEffect(() => {
    if (selectedWorkflow?.config) {
      if (selectedForm?.type === FLOW_TYPE.design) {
        dispatch(designActions.setEditorChanged(true));
        loadEditor();
      }
    }
  }, [selectedForm, selectedWorkflow?.config]);

  const getPdfs = async (formId) => {
    const result = await (await DesignService.getAllPdfs(formId)).json();
    if (result.statusCode === 200 && result.data?.length) {
      const mappedResult = result.data.map((pdf) => {
        return {
          ...pdf,
          filePath: `${BASE_URL}/${pdf.filePath}`,
          isSelected: false,
        };
      });
      mappedResult[0].isSelected = true;
      setAllPdf(mappedResult);
    }
  };

  const elementLoaded = () => {
    const promise = new Promise((resolve, reject) => {
      let elem = document.querySelector(".bingo");

      if (!elem) {
        const timeoutId = setInterval(() => {
          elem = document.querySelector(".bingo");
          if (elem) {
            resolve(elem);
            clearInterval(timeoutId);
          }
        }, 100);
      } else {
        resolve(elem);
      }
    });

    return promise;
  };

  const documentRendered = async (currPage) => {
    const pdfEditor = await elementLoaded();
    const content = await html2canvas(pdfEditor, {
      scale: 4,
      logging: false,
      allowTaint: true,
      useCORS: true,
      imageTimeout: 1000,
    });
    const result = content.toDataURL();
    exportList.current.push(result);
    if (currPage < totalPages.current) {
      setUpdatePage(currPage + 1);
    } else {
      generateExportPdf();
    }
  };

  const generateExportPdf = async () => {
    if (exportList.current.length === totalPages.current) {
      const canvas = document.getElementById("hidden-canvas-editor");
      let pdfInstance = null;
      const promises = [];
      exportList.current.forEach((pageContent, i) => {
        const newPromise = new Promise(async (resolve, reject) => {
          const imageDimension = await getImageSize(pageContent);
          const landscape = isLandscape(imageDimension);

          if (!pdfInstance) {
            pdfInstance = new jsPDF({
              orientation: landscape ? "landscape" : "portrait",
              unit: "px",
              format: [canvas.width, canvas.height],
            });
          }

          if (i > 0) {
            pdfInstance.addPage(
              undefined,
              landscape ? "landscape" : "portrait"
            );
          }

          pdfInstance.addImage(
            pageContent,
            "jpeg",
            0,
            0,
            canvas.width,
            canvas.height
          );

          resolve(true);
        });

        promises.push(newPromise);
      });
      const resolvedAll = await Promise.all(promises);

      if (resolvedAll.length) {
        pdfInstance.save(`document.pdf`);
        // stop export loading
        setExportLoader(false);
      }
    }
  };

  const temporaryRender = async (pdfConfig, canvasElem) => {
    const pdfDoc = await pdfJS.getDocument(pdfConfig.url).promise;
    totalPages.current = pdfDoc.numPages;
    if (totalPages.current > 2) {
      setUpdatePage(2);
      // start export loading
      setExportLoader(true);
    } else {
      // only one page remaining so export that
      generateExportPdf();
    }
  };

  const getImageSize = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
    });
  };

  const isLandscape = (imageDimension) => {
    return imageDimension.width > imageDimension.height;
  };

  const exportAsPdf = async () => {
    if (selectedWorkflow) {
      setExportLoading(true);
      if (selectedForm.type === FLOW_TYPE.design) {
        WorkflowService.exportAsPdf(selectedWorkflow.id)
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            window.open(url, "_blank");
          })
          .catch((err) => console.error(err))
          .finally(() => setExportLoading(false));
      } else {
        // get canvas to extract pdf from
        const canvas = document.getElementById("hidden-canvas-editor");

        // find the selected pdf from config,
        const matchingPdf = selectedWorkflow.config.contents.find(
          (it) => it.id === selectedPdf.id
        );

        if (matchingPdf) {
          await temporaryRender(matchingPdf, canvas);
        }

        setExportLoading(false);
      }
    }
  };

  const handleApprove = (e) => {
    e.stopPropagation();
    if ([ROLES.receiver, ROLES.creator].includes(currentRole?.role.role)) {
      exportAsPdf();
    } else {
      const roleFromUrl = window.atob(searchParams.get("role"));
      const actionName =
        roleFromUrl === ROLES.gatekeeper
          ? ROLE_STATUS.review
          : ROLE_STATUS.approved;

      setConfirmDialog({ isOpen: true, action: actionName });
    }
  };

  const handleReject = (e) => {
    e.stopPropagation();
    setConfirmDialog({ isOpen: true, action: ROLE_STATUS.rejected });
  };

  const handleLogoClick = () => {
    navigate("/dashboard", { replace: true });
  };

  // handle click from outside to remove all selections
  window.document.onclick = (event) => {
    const currentRole = window.atob(searchParams.get("role"));
    const propertyContainer = window.document.getElementsByClassName(
      "ant-upload-list ant-upload-list-picture-card"
    );
    if (
      currentRole === ROLES.approver &&
      !propertyContainer[0]?.contains(event.target)
    ) {
      setFileList((files) =>
        files.map((it) => {
          it.isSelected = false;
          return it;
        })
      );
      setBtnDisabled(true);
    }
  };

  const signModeChange = (value) => {
    setSignMode(value);
  };

  const onConfirmSuccess = async () => {
    setApproveLoader(true);
    const { recipients } = selectedWorkflow;
    const sorted = recipients.sort((a, b) => a.order - b.order);
    const roleFromUrl = window.atob(searchParams.get("role"));
    const nextPendingRecipient = sorted.find(
      (it) =>
        it.status === ROLE_STATUS.pending &&
        it.role.role === roleFromUrl &&
        it.user.email === loggedInUser.email
    );

    const status =
      confirmDialog.action === ROLE_STATUS.rejected
        ? ROLE_STATUS.rejected
        : ROLE_STATUS.approved;

    const remarks =
      confirmDialog.action === ROLE_STATUS.rejected
        ? rejectRef.current.resizableTextArea.textArea.value
        : null;

    // manipulate dom to place signatures, approve and reject flags.
    let signedWith = "";
    let simpleApprove = false;
    if (
      signKeys.length &&
      currentRole.role.role === ROLES.approver &&
      selectedForm?.type === FLOW_TYPE.design
    ) {
      const selectedSign = fileList.find((it) => it.isSelected);
      // find the nodes containing text nodes that are in signs array
      signKeys.forEach((it, index) => {
        const xpath = `//node()[text()='${it}']`;
        const matchingElement = window.document.evaluate(
          xpath,
          document,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;
        const signElem = window.document.createElement("div");

        if (!matchingElement && !index) {
          message.error(
            "Please make sure signature placeholders are valid with publishing content."
          );
          setApproveLoader(false);
          return;
        }

        if (confirmDialog.action === ROLE_STATUS.approved) {
          if (signMode) {
            // signature approve
            signElem.style.backgroundImage = `url(${selectedSign.url})`;
            signedWith = selectedSign.uid;
          } else {
            // simple approve
            signElem.style.backgroundImage = `url(/assets/approved.png)`;
            signElem.style.transform = "rotate(45deg)";
            signElem.style.filter = "grayscale(65%)";
            simpleApprove = true;
          }
        } else if (confirmDialog.action === ROLE_STATUS.rejected) {
          signElem.style.backgroundImage = `url(/assets/rejected.png)`;
          signElem.style.transform = "rotate(45deg)";
          signElem.style.filter = "grayscale(65%)";
        }

        signElem.style.width = "100%";
        signElem.style.height = "60px";
        signElem.style.backgroundRepeat = "no-repeat";
        signElem.style.backgroundSize = "contain";
        signElem.style.backgroundPosition = "center";
        matchingElement.textContent = null;
        matchingElement.appendChild(signElem);
      });
    }

    // update recipient
    const updateResult = await (
      await WorkflowService.updateRecipient(
        nextPendingRecipient.id,
        selectedForm.id,
        {
          sign: signedWith,
          simpleApprove,
          status,
          actedOn: moment().utc(true).toDate(),
          remarks,
        }
      )
    ).json();
    tokenValidator(updateResult, navigate);
    setApproveLoader(false);
    if (updateResult.statusCode === 200) {
      setConfirmDialog({ isOpen: false });
      setProceedBtn(
        PROCEED_BTN_CONFIG_MAP(confirmDialog.action, currentRole.role.role)
      );
    } else {
      message.error("Error updating document");
    }

    if (selectedForm.type === FLOW_TYPE.upload) {
      const updatePayload = {
        config: JSON.stringify(editorRef.current),
      };
      const result = await (
        await WorkflowService.updateWorkflow(selectedWorkflow.id, updatePayload)
      ).json();
      tokenValidator(result, navigate);
      setApproveLoader(false);
      if (result.statusCode === 200) {
        setConfirmDialog({ isOpen: false });
        setProceedBtn(
          PROCEED_BTN_CONFIG_MAP(confirmDialog.action, currentRole.role.role)
        );
      } else {
        message.error("Error updating flow");
      }
      setSelectedForm(null);
    } else if (selectedForm?.type === FLOW_TYPE.design) {
      setSelectedForm(null);
      setSelectedWorkflow(null);
    }
  };

  const footer = [
    <Button key={0} onClick={() => setConfirmDialog({ isOpen: false })}>
      cancel
    </Button>,
    <Button
      key={1}
      type="primary"
      disabled={
        selectedForm?.type === FLOW_TYPE.design
          ? btnDisabled &&
            signMode &&
            currentRole?.role.role === ROLES.approver &&
            confirmDialog.action === ROLE_STATUS.approved
          : false
      }
      onClick={onConfirmSuccess}
      loading={approveLoader}
    >
      {DIALOG_UI_CONTENT[confirmDialog.action]?.btnName}
    </Button>,
  ];

  const uploadSignature = async ({ file, onSuccess }) => {
    const result = await (
      await WorkflowService.uploadSignature({ file })
    ).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      const newFileList = {
        uid: result.data.id,
        isDefault: result.data.isDefault,
        url: `${BASE_URL}/signature/download/${result.data.id}`,
        status: "done",
      };
      setFileList([...fileList, newFileList]);
      onSuccess("ok");
    } else {
      message.error("Error uploading signature.");
    }
  };

  const onFileRemove = async (event, file) => {
    event.stopPropagation();
    const result = await (
      await WorkflowService.removeSignature(file.uid)
    ).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      setFileList((files) => files.filter((it) => it.uid !== file.uid));
      message.success("File removed successfully.");
    } else {
      message.error("Error removing signature");
    }
  };

  const onFileChoose = (event, file) => {
    event.stopPropagation();
    setFileList((state) =>
      state.map((it) => {
        it.isSelected = it.uid === file.uid;
        return it;
      })
    );
    setBtnDisabled(false);
  };

  const customFileList = (_, file) => {
    return (
      <div
        className={
          file.isSelected
            ? "ant-upload-list-item ant-upload-list-item-done signature-selected"
            : "ant-upload-list-item ant-upload-list-item-done "
        }
        onClick={(event) => onFileChoose(event, file)}
      >
        <a
          className="ant-upload-list-item-thumbnail"
          href={file?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={file?.url} className="ant-upload-list-item-image" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="ant-upload-list-item-name"
          href={file?.url}
        ></a>
        <span className="ant-upload-list-item-actions">
          <a
            onClick={(event) => event.stopPropagation()}
            href={file?.url}
            target="_blank"
            rel="noopener noreferrer"
            title="Preview file"
          >
            <EyeOutlined />
          </a>
          <button
            style={{ backgroundColor: "transparent" }}
            onClick={(event) => onFileRemove(event, file)}
            title="Remove file"
            type="button"
            className="ant-btn  ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"
          >
            <DeleteOutlined />
          </button>
        </span>
      </div>
    );
  };

  const handlePdfSelect = (event, selectedPdf) => {
    event.stopPropagation();
    const updatedPdfList = allPdf.map((pdf) => {
      pdf.isSelected = pdf.id === selectedPdf.id;
      return pdf;
    });
    setAllPdf(updatedPdfList);
  };

  const PDF_UI = () => {
    if (selectedForm?.config?.contents.length) {
      const readOnly =
        ROLES.approver !== currentRole?.role.role && !isRejected.current;
      return (
        <div className="pdf-doc-view">
          <FormStatusComponent
            smallView={isStatusSmall.current}
            from={"doc"}
            render={render}
          />
          <div className="pdf-list">
            <div className="pdf-items">
              {allPdf?.map((pdf, index) => {
                return (
                  <div
                    className="pdf-item-wrap"
                    style={
                      pdf.isSelected
                        ? {
                            backgroundColor: "rgba(22, 119, 255, 0.1)",
                            border: "1px solid #1677ff",
                          }
                        : {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            border: "1px solid rgb(216, 216, 216)",
                          }
                    }
                    key={index}
                    onClick={(e) => handlePdfSelect(e, pdf)}
                  >
                    <FilePdfOutlined />
                    <span>PDF - {index + 1}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {selectedPdf ? (
            <PdfViewerComponent
              editorRef={editorRef}
              readonly={readOnly}
              hideTools={true}
              from={"doc"}
              currentPdf={selectedPdf}
              changePage={updatePage}
              docRendered={documentRendered}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <Empty
          description="Unable to load document"
          image={
            <FilePdfOutlined
              style={{
                fontSize: 60,
                color: "#bfbfbf",
              }}
            />
          }
          style={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
            padding: "55px 30px",
            borderRadius: 10,
            background: "rgba(0,0,0,0.03)",
          }}
        />
      );
    }
  };

  const afterModalClose = () => {
    setRender((value) => {
      return value + 1;
    });
  };

  return (
    <div className="document-container">
      {searchParams.get("mode") !== "print" ? (
        <header className="header-wrapper">
          <div className="logo-container" onClick={handleLogoClick}>
            <img src="/assets/logo.png" alt="WFC" />
          </div>
          {proceedBtn?.disabled && alreadyPublish ? (
            <p className="no-margin doc-note">
              This document has already been {proceedBtn?.name?.toLowerCase()}
            </p>
          ) : null}
          <div className="dash-nav">
            <Space size={"middle"}>
              {proceedBtn?.name == "---" &&
              !isRejected.current &&
              ![ROLES.receiver, ROLES.creator].includes(
                currentRole?.role.role
              ) ? (
                <Button onClick={handleReject}>Reject</Button>
              ) : null}

              {!onlyView ? (
                <Button
                  loading={exportLoading}
                  type="primary"
                  disabled={proceedBtn?.disabled}
                  style={{
                    backgroundColor: proceedBtn?.color,
                    color: "#ffffff",
                  }}
                  onClick={handleApprove}
                >
                  {proceedBtn?.name}
                </Button>
              ) : null}
              <AvatarMenu />
            </Space>
          </div>
        </header>
      ) : null}
      <div className="editor-container">
        <div className="editor-wrap">
          {selectedForm?.type === FLOW_TYPE.design ? (
            <>
              <FormStatusComponent smallView={true} render={render} />
              <div id="editorjs"></div>
            </>
          ) : (
            PDF_UI()
          )}
        </div>
      </div>
      {/* TODO: title for form should change as per approval role and signature selected. */}
      <Modal
        title="Are you sure?"
        maskClosable={false}
        open={confirmDialog.isOpen}
        onCancel={() => setConfirmDialog({ isOpen: false })}
        footer={footer}
        afterClose={afterModalClose}
      >
        <p>{DIALOG_UI_CONTENT[confirmDialog.action]?.title}.</p>
        {currentRole?.role.role === ROLES.approver &&
        confirmDialog.action === ROLE_STATUS.approved &&
        selectedForm.type === FLOW_TYPE.design ? (
          <div className="approve-container">
            <Divider orientation="left" style={{ fontSize: "14px" }}>
              Add Signature
            </Divider>
            <Space direction="vertical">
              <Space align={"baseline"}>
                <Paragraph>Signature mode</Paragraph>
                <Switch
                  checked={signMode}
                  checkedChildren="Advanced"
                  unCheckedChildren="Simple"
                  defaultChecked
                  onChange={signModeChange}
                />
              </Space>
              {signMode ? (
                <Space align="center">
                  <ImgCrop rotate>
                    <Upload
                      customRequest={uploadSignature}
                      listType="picture-card"
                      fileList={fileList}
                      onRemove={onFileRemove}
                      itemRender={customFileList}
                    >
                      {fileList.length < 25 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </Space>
              ) : null}
            </Space>
          </div>
        ) : null}

        {confirmDialog.action === ROLE_STATUS.rejected ? (
          <div className="approve-container">
            <Divider orientation="left" style={{ fontSize: "14px" }}>
              Add Remarks
            </Divider>
            <Space direction="vertical">
              <TextArea
                ref={rejectRef}
                cols={100}
                rows={4}
                placeholder="Type here ..."
                maxLength={300}
                showCount
              />
            </Space>
          </div>
        ) : null}
      </Modal>
      {exportLoader ? (
        <div className="pdf-export-loader">
          <div className="pdf-export-content">
            <div className="loader-lottie">
              <Spin />
            </div>
            <p>Please wait while we export your document...</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DocumentComponent;
