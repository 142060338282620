import { DashboardService } from "../components/dashboard/services/dashboard.service";

export const createForm = (values) => {
    return async () => {
        try {
            return await (await DashboardService.newForm(values)).json();
        } catch (error) {
            console.error('error creating new form', error);
            return null;
        }
    }
}

export const getAllForms = (_) => {
    return async () => {
        try {
            return await (await DashboardService.allForms()).json();
        } catch (error) {
            console.error('error fetching all forms', error);
            return null;
        }
    }
}

export const getOneForm = (formId) => {
    return async () => {
        try {
            return await (await DashboardService.getForm(formId)).json();
        } catch (error) {
            console.error('error fetching form', error);
            return null;
        }
    }
}

export const updateForm = (payload, formId) => {
    return async () => {
        try {
            return await (await DashboardService.updateForm(payload, formId)).json();
        } catch (error) {
            console.error('error updating form', error);
            return null;
        }
    }
}

export const deleteForm = (formId) => {
    return async () => {
        try {
            return await (await DashboardService.deleteForm(formId)).json();
        } catch (error) {
            console.error('error removing form', error);
            return null;
        }
    }
}