export default class ParagraphPlugin {
    static title = 'Paragraph';

    static get isInline() {
        return true;
    }

    static get isReadonlySupported() {
        return true;
    }

    constructor({ readOnly }) {
        this.input = null;
        this.readOnly = readOnly;
    }

    surround(range) {

    }

    checkState(selection) {

    }

    render() {
        if (this.readOnly) {
            this.input = document.createElement('p');
        } else {
            this.input = document.createElement('input');
        }

        return this.input;
    }

    save(blockContent) {
        return {
            data: blockContent.value
        }
    }
}