import { Button, message, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCALSTORAGE_KEYS } from "../../constant/constants";
import { LocalStorageService } from "../../services/localstorage.service";
import { forgotPass } from '../../store/user.effect';

const { Title, Paragraph } = Typography;
const ForgotStatus = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [resetClickCount, setResetClickCount] = useState(0);

    const resendEmail = async () => {
        const newCount = resetClickCount + 1;
        setResetClickCount(newCount);
        if (newCount === 3) {
            LocalStorageService.setToLocalStorage(LOCALSTORAGE_KEYS.resendLock, true);
        }
        if (location.state.email) {
            const result = await forgotPass({ email: location.state.email })();
            if (result.statusCode === 200) {
                //
            } else {
                message.error(`Error resetting password`);
            }
        }
    }

    useEffect(() => {

        if (LocalStorageService.getFromLocalStorage(LOCALSTORAGE_KEYS.resendLock)) {
            setResetClickCount(3);
        }

    }, []);

    const navigateToHome = () => {
        navigate('/', { replace: true });
    }

    return (
        <section className='signup-wrapper'>
            <header className='header-wrapper'>
                <div className='logo-container' onClick={navigateToHome}>
                    <img src='assets/logo.png' alt='WFC' />
                </div>
            </header>
            <main className='signup-middle'>
                <div className="signup-card forgot-stat-card">
                    <div className="forgot-status-img"></div>
                    <Title level={3} className='signup-card-head' style={{ marginBottom: 10 }}>Check your email</Title>
                    <Paragraph className="signup-card-sub-head">We have sent a password reset link to your registered email.</Paragraph>
                    <Button type='primary' className="resend-btn" onClick={resendEmail} disabled={resetClickCount === 3}>Resend</Button>
                </div>
            </main>
        </section>
    );
};

export default ForgotStatus;