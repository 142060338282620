import {
  AppstoreFilled,
  CaretLeftOutlined,
  CaretRightOutlined,
  FileTextFilled,
  FormOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { GuardedRoute } from "./common/guarded-route";
import LoaderOverlay from "./common/loader-overlay";
import { LoginGuard } from "./common/login-guard";
import AuthEmailComponent from "./components/auth/authenticate-email";
import ChangePassComponent from "./components/auth/change-pass";
import ForgotPassComponent from "./components/auth/forgot-pass";
import ForgotStatus from "./components/auth/forgot-pass-status";
import SignInComponent from "./components/auth/sign-in";
import SignUpComponent from "./components/auth/sign-up";
import SignUpSuccessComponent from "./components/auth/signup-success";
import DashboardComponent from "./components/dashboard/dashboard";
import DocumentComponent from "./components/document/document";
import FormsComponent from "./components/forms/forms";
import PageNotFoundComponent from "./components/page-not-found/not-found";
import SignatureComponent from "./components/signature/signatures";
import DesignComponent from "./components/workflow-design/design";
import FormStatusComponent from "./components/workflow-design/workflow-status";

const { Header, Sider, Content } = Layout;
const sideBarItems = [
  {
    key: "1",
    icon: <AppstoreFilled />,
    label: "Dashboard",
  },
  {
    key: "2",
    icon: <FileTextFilled />,
    label: "Forms",
  },
  {
    key: "3",
    icon: <FormOutlined />,
    label: "Signatures",
  },
];

function App() {
  const isLoading = useSelector((state) => state.app.isLoading);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onMenuItemSelect = (item) => {
    switch (item.key) {
      case "1":
        navigate("/dashboard", { replace: true });
        break;

      case "2":
        navigate("/forms", { replace: true });
        break;

      case "3":
        navigate("/signatures", { replace: true });
        break;

      default:
        navigate("/dashboard", { replace: true });
        break;
    }
  };

  const handleOnMenuClick = ({ item, key, domEvent }) => {
    domEvent.nativeEvent.stopPropagation();
    onMenuItemSelect({ key });
  };

  const getDefaultMenu = () => {
    const matchingMenu = sideBarItems.find((it) => {
      return (
        it.label.toLowerCase() ===
        location.pathname.replace("/", "").toLowerCase()
      );
    });

    return [matchingMenu?.key || "2"];
  };

  return (
    <div className="wrapper">
      <Routes>
        <Route
          element={
            <>
              <Layout className="mainLayout">
                <Sider theme="light" className="sidebar" collapsed={collapsed}>
                  <div className="sidebar-trigger-container">
                    <button
                      className="sidebar-trigger"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      {collapsed ? (
                        <CaretRightOutlined className="sidebar-trigger-icon" />
                      ) : (
                        <CaretLeftOutlined className="sidebar-trigger-icon" />
                      )}
                    </button>
                  </div>
                  <div className="sidebar-items-container">
                    <Menu
                      items={sideBarItems}
                      defaultSelectedKeys={getDefaultMenu}
                      // onSelect={onMenuItemSelect}
                      onClick={handleOnMenuClick}
                    />
                  </div>
                </Sider>
                <Content>
                  <Outlet />
                </Content>
              </Layout>
            </>
          }
        >
          <Route
            path="/dashboard"
            element={
              <GuardedRoute>
                <DashboardComponent />
              </GuardedRoute>
            }
          />
          <Route
            path="/forms"
            element={
              <GuardedRoute>
                <FormsComponent />
              </GuardedRoute>
            }
          />
          <Route
            path="/signatures"
            element={
              <GuardedRoute>
                <SignatureComponent />
              </GuardedRoute>
            }
          />
          <Route
            path="/design/:designId"
            element={
              <GuardedRoute>
                <DesignComponent />
              </GuardedRoute>
            }
          />
          <Route
            path="/status/:formId"
            element={
              <GuardedRoute>
                <FormStatusComponent />
              </GuardedRoute>
            }
          />
        </Route>
        <Route element={<Outlet />}>
          <Route
            path="/doc/:formId/:workflowId"
            element={
              <GuardedRoute>
                <DocumentComponent />
              </GuardedRoute>
            }
          />
          <Route
            path="/"
            element={
              <LoginGuard>
                <SignInComponent />
              </LoginGuard>
            }
          />
          <Route
            path="/register"
            element={
              <LoginGuard>
                <SignUpComponent />
              </LoginGuard>
            }
          />
          <Route
            path="/signUpSuccess"
            element={
              <LoginGuard>
                <SignUpSuccessComponent />
              </LoginGuard>
            }
          />
          <Route
            path="/emailAuth"
            element={
              <LoginGuard>
                <AuthEmailComponent />
              </LoginGuard>
            }
          />
          <Route
            path="/forgot"
            element={
              <LoginGuard>
                <ForgotPassComponent />
              </LoginGuard>
            }
          />
          <Route
            path="/forgotStatus"
            element={
              <LoginGuard>
                <ForgotStatus />
              </LoginGuard>
            }
          />
          <Route
            path="/reset"
            element={
              <LoginGuard>
                <ChangePassComponent />
              </LoginGuard>
            }
          />
          <Route path="/pageNotFound" element={<PageNotFoundComponent />} />
          <Route path="*" element={<PageNotFoundComponent />} />
        </Route>
      </Routes>
      {isLoading ? <LoaderOverlay /> : null}
    </div>
  );
}

export default App;
