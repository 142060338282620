import { Button, Divider, Space, Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import AvatarMenu from "../../common/avatar-menu";
import NotificationComponent from "../../common/notification";
import {
  ROLES,
  ROLE_COLORS,
  ROLE_STATUS,
  TABLE_CONSTANTS,
} from "../../constant/constants";
import { DashboardService } from "./services/dashboard.service";

const { Paragraph, Title } = Typography;

const DashboardComponent = () => {
  const [formsFlow, setFormsFlow] = useState(null);
  const [needsAction, setNeedsAction] = useState(0);
  const [waitingOthers, setWaitingOthers] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [isExpiring, setIsExpiring] = useState(0);
  const [dataSource, setDataSource] = useState(null);
  const [csv, setCsv] = useState(null);
  const csvLinkRef = useRef();
  const timeOutId = useRef(null);
  const [exportLoader, setExportLoader] = useState(false);

  const columns = [
    {
      title: "Form Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 100,
    },
    {
      title: "Recipients",
      dataIndex: "recipient",
      key: "recipient",
      ellipsis: true,
      render: (_, { recipient }) => (
        <>
          {recipient?.length
            ? recipient?.map((tag, index) => {
                return (
                  <Tooltip key={index} title={tag.role}>
                    <Tag style={{ margin: "0 5px 5px 5px" }} color={tag.color}>
                      {tag.fullName}
                    </Tag>
                  </Tooltip>
                );
              })
            : "Not Available"}
        </>
      ),
    },
    {
      title: "Published",
      dataIndex: "published",
      key: "published",
      ellipsis: true,
    },
    {
      title: "Completed On",
      dataIndex: "completed",
      key: "completed",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      ellipsis: true,
      width: 200,
      render: (link, _) => {
        return (
          <Button disabled={!link} onClick={(e) => handleViewDoc(e, link)}>
            View Document
          </Button>
        );
      },
    },
  ];

  const handleViewDoc = (e, link) => {
    e.stopPropagation();
    if (link) {
      window.open(link, "_blank");
    }
  };

  const formsWithFlow = async () => {
    const result = await (await DashboardService.allFormsWithWorkflow()).json();
    if (result.statusCode === 200) {
      setFormsFlow(result.data);

      // loop through all forms and deduce the needed metrics
      result.data.map((it) => {
        if (it.workflow?.recipients) {
          // get the current user from recipient
          // filter roles like gatekeeper and approver whose status are in pending state.

          const needsAction = it.workflow.recipients.filter(
            (recipient) =>
              recipient.user.email === it.user.email &&
              recipient.status === ROLE_STATUS.pending &&
              [ROLES.gatekeeper, ROLES.approver].includes(recipient.role.role)
          );

          // push the count to needsAction state.
          if (needsAction.length) {
            setNeedsAction((state) => state + 1);
          }

          // get all recipients except current user and then deduce metrics for waiting others list.
          // filter roles like gatekeeper and approver whose status are in pending state.
          const waitingOthers = it.workflow.recipients.filter(
            (recipient) =>
              recipient.user.email !== it.user.email &&
              [ROLES.gatekeeper, ROLES.approver].includes(
                recipient.role.role
              ) &&
              recipient.status === ROLE_STATUS.pending
          );

          if (waitingOthers.length) {
            setWaitingOthers((state) => state + 1);
          }

          // loop through the links array and find any expired link.
          const anyExpired = it.workflow.links.some((link) => {
            const windowStart = moment(link.expiry).subtract(12, "hours");
            const windowEnd = moment(link.expiry);
            return moment().isBetween(windowStart, windowEnd);
          });

          if (anyExpired) {
            setIsExpiring((state) => state + 1);
          }

          // loop through all recipients and check if all recipients have status APPROVED OR RECEIVED.
          const completed = it.workflow.recipients.every((recipient) =>
            [ROLE_STATUS.approved, ROLE_STATUS.received].includes(
              recipient.status
            )
          );

          if (completed) {
            setCompleted((state) => state + 1);
          }
        }
        return it;
      });
    }
  };

  const formsHistory = () => {
    if (formsFlow) {
      // sort based on date
      formsFlow.sort((a, b) => {
        if (moment(a.lastPublished).isBefore(moment(b.lastPublished))) {
          return 1;
        } else if (moment(a.lastPublished).isAfter(moment(b.lastPublished))) {
          return -1;
        } else {
          return 0;
        }
      });

      const dataSource = formsFlow.map((it, index) => {
        const currentTime = moment();
        const publishTime = moment.utc(it.lastPublished);

        const diff = publishTime.diff(currentTime);

        const completedOn = it.workflow?.links.find(
          (link) => link.export
        )?.createdAt;

        const exportLink = it.workflow?.links.find((link) => link.export)?.url;
        const approveLink = it.workflow?.links.find(
          (link) => link.role.role === ROLES.approver
        )?.url;
        const reviewLink = it.workflow?.links.find(
          (link) => link.role.role === ROLES.gatekeeper
        )?.url;

        // sort the recipients according to their order number.
        it.workflow?.recipients.sort((a, b) => a.order - b.order);

        const record = {
          key: index,
          name: it.name,
          recipient: it.workflow?.recipients
            .filter((it) => it.role.role !== ROLES.creator)
            .map((recipient) => {
              return {
                color: ROLE_COLORS[recipient.role.role],
                role: recipient.role.role,
                fullName: !recipient.user.firstName
                  ? recipient.user.email
                  : `${recipient.user.firstName} ${recipient.user.lastName}`,
              };
            }),
          published: moment.duration(diff).humanize(true),
          completed: completedOn
            ? moment(completedOn).format("DD/MM/YY hh:mm A")
            : "Unfinished",
          action: exportLink || approveLink || reviewLink,
        };
        return record;
      });

      setDataSource(dataSource);
    }
  };

  useEffect(() => {
    if (!formsFlow) {
      formsWithFlow();
    }
  }, [formsFlow]);

  useEffect(() => {
    formsHistory();
  }, [formsFlow]);

  useEffect(() => {
    // once csv link ref is available, dispatch a click event.
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
      csvLinkRef.current = null;
      timeOutId.current = setTimeout(() => {
        setExportLoader(false);
      }, 500);
    }

    // clear timeout id
    return () => {
      clearTimeout(timeOutId);
    };
  }, [csv]);

  const generateCsv = () => {
    // set export button loader.
    setExportLoader(true);

    // generate csv headers.
    const csvHeaders = [
      { label: "Document Name", key: "documentName" },
      { label: "Reviewed By", key: "reviewedBy" },
      { label: "Approved By", key: "approvedBy" },
      { label: "Received By", key: "receivedBy" },
      { label: "Reviewed Date", key: "reviewedDate" },
      { label: "Approved Date", key: "approvedDate" },
      { label: "Received Date", key: "receivedDate" },
      { label: "Review Status", key: "reviewStatus" },
      { label: "Approved Status", key: "approveStatus" },
      { label: "Received Status", key: "receivedStatus" },
    ];

    // generate csv data.
    const fullCsvData = formsFlow.map((flow) => {
      const reviewers = flow.workflow.recipients
        .filter((recipient) => recipient.role.role === ROLES.gatekeeper)
        .map((recipient) => {
          return {
            name: recipient.user.lastName
              ? `${recipient.user.firstName} ${recipient.user.lastName}`
              : recipient.user.firstName,
            dateFormatted: moment
              .utc(recipient.actedOn)
              .format("DD/MM/YY hh:mm A"),
            dateIso: recipient.actedOn,
            status: recipient.status,
          };
        });

      const approvers = flow.workflow.recipients
        .filter((recipient) => recipient.role.role === ROLES.approver)
        .map((recipient) => {
          return {
            name: recipient.user.lastName
              ? `${recipient.user.firstName} ${recipient.user.lastName}`
              : recipient.user.firstName,
            dateFormatted: moment
              .utc(recipient.actedOn)
              .format("DD/MM/YY hh:mm A"),
            dateIso: recipient.actedOn,
            status: recipient.status,
          };
        });

      const receivers = flow.workflow.recipients
        .filter((recipient) => recipient.role.role === ROLES.receiver)
        .map((recipient) => {
          return {
            name: recipient.user.lastName
              ? `${recipient.user.firstName} ${recipient.user.lastName}`
              : recipient.user.firstName,
            dateFormatted: moment
              .utc(recipient.actedOn)
              .format("DD/MM/YY hh:mm A"),
            dateIso: recipient.actedOn,
            status: recipient.status,
          };
        });

      return {
        documentName: flow.name,
        reviewedBy: reviewers.map((it) => it.name).join(", "),
        approvedBy: approvers.map((it) => it.name).join(", "),
        receivedBy: receivers.map((it) => it.name).join(", "),
        reviewedDate: reviewers.map((it) => it.dateFormatted).join(", "),
        approvedDate: approvers.map((it) => it.dateFormatted).join(", "),
        receivedDate: receivers.map((it) => it.dateFormatted).join(", "),
        reviewStatus: reviewers.map((it) => it.status).join(", "),
        approveStatus: approvers.map((it) => it.status).join(", "),
        receivedStatus: receivers.map((it) => it.status).join(", "),
      };
    });

    // set both headers and data to state variables.
    setCsv({
      data: fullCsvData,
      headers: csvHeaders,
    });
  };

  const getHistoryHeight = () => {
    const height =
      document.querySelector(".metrics-history")?.clientHeight -
      (TABLE_CONSTANTS.table_header +
        TABLE_CONSTANTS.table_paginator +
        TABLE_CONSTANTS.table_margin);
    return height || 300;
  };

  return (
    <div className="dashboard-container">
      <header className="header-wrapper">
        <div className="logo-container">
          <img src="/assets/logo.png" alt="WFC" />
        </div>
        <div className="dash-nav">
          <Space size="middle">
            <AvatarMenu />
            <NotificationComponent />
          </Space>
        </div>
      </header>
      <div className="metrics-wrap">
        <div className="metrics-container">
          <div className="action-need">
            <Paragraph strong className="no-margin">
              {needsAction}
            </Paragraph>
            <span>In Progress</span>
          </div>
          <Divider type="vertical"></Divider>
          <div className="waiting-others">
            <Paragraph strong className="no-margin">
              {waitingOthers}
            </Paragraph>
            <span>Awaiting Action</span>
          </div>
          <Divider type="vertical"></Divider>
          <div className="expiring">
            <Paragraph strong className="no-margin">
              {isExpiring}
            </Paragraph>
            <span>Expiring Soon</span>
          </div>
          <Divider type="vertical"></Divider>
          <div className="completed">
            <Paragraph strong className="no-margin">
              {completed}
            </Paragraph>
            <span>Completed</span>
          </div>
          {/* <Divider type="vertical"></Divider>
          <div className="expired">
            <Paragraph strong className="no-margin">
              {completed}
            </Paragraph>
            <span>Expired</span>
          </div> */}
        </div>
        <div className="metrics-export">
          <Button
            type="primary"
            onClick={generateCsv}
            style={{ margin: "15px 10px 0px 10px" }}
            disabled={!formsFlow?.length}
            title="Export to CSV"
            loading={exportLoader}
          >
            Export to CSV
            {csv ? (
              <CSVLink
                ref={csvLinkRef}
                data={csv.data}
                headers={csv.headers}
                filename="export.csv"
              ></CSVLink>
            ) : null}
          </Button>
        </div>
        <div className="metrics-history">
          <Table
            dataSource={dataSource}
            columns={columns}
            size="large"
            scroll={{
              y: getHistoryHeight()
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
