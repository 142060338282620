import { Button, Form, Input, message, Typography } from "antd";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX, FORM_VALIDATOR, USER_ACTIONS } from "../../constant/constants";
import { forgotPass } from '../../store/user.effect';

const { Title, Paragraph } = Typography;


const ForgotPassComponent = () => {

    const [formFilled, setFormFilled] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = (values) => {
        setResetLoading(true);
        forgotPass(values)().then(data => {
            if (data.statusCode === 200) {
                navigate('/forgotStatus', { replace: true, state: { ...values, action: USER_ACTIONS.internal } });
            } else {
                message.error(`Error resetting password`);
                form.resetFields();
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setResetLoading(false);
        })
    };

    const onFormChanges = (_, allValues) => {
        setFormFilled(Object.values(allValues).every(it => it) && EMAIL_REGEX.test(allValues.email));
    }

    const navigateToSignIn = () => {
        navigate('/', { replace: true });
    }


    return (
        <section className='signup-wrapper'>
            <header className='header-wrapper'>
                <div className='logo-container' onClick={navigateToSignIn}>
                    <img src='assets/logo.png' alt='WFC' />
                </div>
                <div className='signup-nav'>
                    <Title level={5} className='no-margin'>Have an account? </Title> <Button className='signIn-btn' onClick={navigateToSignIn}>Sign In</Button>
                </div>
            </header>
            <main className='signup-middle'>
                <div className="signup-card">
                    <div className="forgot-img"></div>
                    <Title level={3} className='signup-card-head' style={{ marginBottom: 10 }}>Forgot Password?</Title>
                    <Paragraph className="signup-card-sub-head">No worries, we'll send you reset instructions.</Paragraph>
                    <Form form={form} name='signup' validateMessages={FORM_VALIDATOR} onFinish={onFinish} onValuesChange={onFormChanges}>
                        <Form.Item name='email' rules={[{ type: 'email', required: true }]}>
                            <Input size="large" placeholder="Email Address" />
                        </Form.Item>
                        <Form.Item className='signup-btn-container' noStyle>
                            <Button type="primary"
                                className='signup-btn'
                                loading={resetLoading}
                                htmlType="submit"
                                disabled={!formFilled}
                                style={formFilled ? { backgroundColor: '#0faf51' } : { backgroundColor: '#e5e5e5' }}
                            >Reset Password</Button>
                        </Form.Item>
                    </Form>
                </div>
            </main>
        </section>
    )
};

export default ForgotPassComponent;