import './list-inline.css';

const ListSvg = `<svg fill="#000000" height="14" width="14" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
viewBox="0 0 487.3 487.3" xml:space="preserve">
<g>
<g>
   <path d="M487.2,69.7c0,12.9-10.5,23.4-23.4,23.4h-322c-12.9,0-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4h322.1
       C476.8,46.4,487.2,56.8,487.2,69.7z M463.9,162.3H141.8c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1
       c12.9,0,23.4-10.5,23.4-23.4C487.2,172.8,476.8,162.3,463.9,162.3z M463.9,278.3H141.8c-12.9,0-23.4,10.5-23.4,23.4
       s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,288.8,476.8,278.3,463.9,278.3z M463.9,394.3H141.8
       c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,404.8,476.8,394.3,463.9,394.3z
        M38.9,30.8C17.4,30.8,0,48.2,0,69.7s17.4,39,38.9,39s38.9-17.5,38.9-39S60.4,30.8,38.9,30.8z M38.9,146.8
       C17.4,146.8,0,164.2,0,185.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,146.8,38.9,146.8z M38.9,262.8
       C17.4,262.8,0,280.2,0,301.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,262.8,38.9,262.8z M38.9,378.7
       C17.4,378.7,0,396.1,0,417.6s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9C77.8,396.2,60.4,378.7,38.9,378.7z"/>
</g>
</g>
</svg>`;

export const ListClassNames = Object.freeze({
    ul: 'tc-ul',
    ulItem: 'tc-ui-li'
});

export default class ListPlugin {

    static title = 'List';

    static get isInline() {
        return true;
    }

    static get isReadonlySupported() {
        return true;
    }

    get state() {
        return this._selected;
    }

    set state(selected) {
        this._selected = selected;

        // toggle active class for button
        this.button.classList.toggle(this.api.styles.inlineToolButtonActive, selected);
    }

    constructor({ api, config }) {
        this.api = api;
        this.tag = 'ul';
        this.button = null;
        this._selected = false;
    }

    render() {
        this.button = document.createElement('button');
        this.button.type = 'button';
        this.button.classList.add(this.api.styles.inlineToolButton);
        this.button.innerHTML = ListSvg;
        return this.button;
    }

    surround(range) {
        if (this.state) {
            // if already selected and applied then reverse the operation
            this.unwrap(range);
            return;
        }

        this.wrap(range);
    }

    unwrap(range) {
        const ancestorContainer = range.commonAncestorContainer;
        const textContent = range.extractContents();
        const liList = Array.from(textContent.children).map(element => element.textContent);
        if (ancestorContainer instanceof Element && ancestorContainer.classList.contains(ListClassNames.ul)) {
            ancestorContainer.remove();
        }
        const newText = document.createTextNode(liList.join('\n'));
        range.insertNode(newText);
    }

    wrap(range) {
        const selectedText = range.extractContents().textContent;
        const listItems = selectedText.split('\n');

        // create ul element 
        const ul = document.createElement(this.tag);
        ul.classList.add(ListClassNames.ul);
        listItems.forEach(item => {
            const li = document.createElement('li');
            li.textContent = `${item}`.trim();
            li.classList.add(ListClassNames.ulItem);
            ul.appendChild(li);
        });

        // insert the new dom to range 
        range.insertNode(ul);

        this.api.selection.expandToTag(ul);
    }


    checkState(selection) {
        // note: try to use api for dom manipulation (not using now because of bugs in the api)
        const anchorNode = selection.anchorNode;
        const ul = anchorNode instanceof Element ? anchorNode : anchorNode.parentElement;
        // if ul is found then probably the list inline tool is checked, so toggle the state
        this.state = !!ul.closest(this.tag);

    }

}