import {
  FilePdfOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Empty,
  Select,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  BASE_URL,
  LOCALSTORAGE_KEYS,
  PDF_WIDGETS,
  RESIZE_HANDLES_STYLES,
  ROLES,
  WIDGET_CONTEXT_MENU,
  WIDGET_TYPES,
} from "../../constant/constants";
import { loggedInUser } from "../../helper/util";
import { localDb } from "../../services/dexie.service";
import { getSignsByEmail } from "../../store/design.effect";
import { designActions } from "../../store/design.reducer";
import SignatureInitialComponent from "../signature/signature-initials";
import SignatureNameComponent from "../signature/signature-name";

const pdfJS = require("pdfjs-dist/build/pdf");
pdfJS.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.js";

const PdfViewerComponent = ({
  currentPdf,
  editorRef,
  readonly = false,
  hideTools = false,
  from,
  changePage = null,
  docRendered = null,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const canvasRef = useRef(null);
  const [widgets, setWidgets] = useState(PDF_WIDGETS);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [currPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [addedWids, setAddedWids] = useState([]);
  const selectedWidgetId = useRef(null);
  const [startMousemove, setStartMousemove] = useState(false);
  const currentScaleFactor = useRef(1);
  const oldScaleFactor = useRef(null);
  const rndRef = useRef(null);
  const recipients = useSelector((state) => state.design.recipients);
  const isMenuCollapsed = useSelector((state) => state.design.isMenuCollapsed);
  const [approvers, setApprovers] = useState([]);
  const [reciBadge, setReciBadge] = useState(recipients[0]?.color || "#f5f5f5");
  const [currRecipient, setCurrRecipient] = useState(recipients[0]);
  const loggedInUserRef = useRef(loggedInUser());
  const [tool, setTool] = React.useState();
  const [isReadonly, setIsReadonly] = useState(readonly);
  const [isRendering, setIsRendering] = useState(false);
  let pageRenderPending = null;
  let pageRendering = null;

  const handleMouseDown = (e) => {
    // disable mouse move events.
    setStartMousemove(false);
    if (from !== "doc") {
      setAddedWids((wids) => {
        return wids.map((widget) => {
          widget.resizable = widget.id == selectedWidgetId.current;
          widget.selected = widget.id == selectedWidgetId.current;
          return widget;
        });
      });
    }
  };

  const handleMouseMove = (e) => {
    // git hub issue for the library - extra transform values are coming on the css styles. this is a workaround for that.
    if (rndRef.current) {
      rndRef.current.offsetFromParent = { top: 0, left: 0 };
    }

    if (startMousemove) {
      const overlay = document.getElementsByClassName("widget-overlay")[0];
      const overlayBounds = overlay.getBoundingClientRect();

      const offsetX = !overlayBounds.x
        ? 0
        : Math.abs(e.clientX - overlayBounds.x);

      const offsetY = !overlayBounds.y
        ? 0
        : Math.abs(e.clientY - overlayBounds.y);

      const widgetDimen = addedWids.find(
        (widget) => widget.id == selectedWidgetId.current
      );

      const elemX = offsetX + widgetDimen.width;
      const elemY = offsetY + widgetDimen.height;

      const outofBoundsX = elemX <= overlayBounds.width;
      const outofBoundsY = elemY <= overlayBounds.height;

      if (outofBoundsX && outofBoundsY) {
        setAddedWids((wids) => {
          return wids.map((widget) => {
            if (widget.id == selectedWidgetId.current) {
              widget.x = offsetX;
              widget.y = offsetY;
            }
            return widget;
          });
        });
      }
    }
  };

  const loadWidgets = async (widgets) => {
    if (widgets.length) {
      // find current signature of each user by email.
      const userEmails = [...new Set(widgets.map((widget) => widget.user))];
      const payload = { emails: userEmails };
      const result = await getSignsByEmail(payload)();

      const widgetsUpdated = widgets.map((wid) => {
        const matchingSign = result.data?.find(
          (sign) => sign.email === wid.user
        );
        return {
          ...wid,
          signatureFont: matchingSign?.signatureFont,
          signatureInitials: matchingSign?.signatureInitials,
          signatureName: matchingSign?.signatureName,
          signatureSerial: matchingSign?.userId,
          signImg: `${BASE_URL}/signature/download/${matchingSign?.id}`,
        };
      });

      // some issue here check
      setAddedWids(widgetsUpdated);
    }
  };

  const renderPage = async (pageNumber) => {
    pageRendering = true;
    setIsRendering(true);

    const pdfViewerArea = document.querySelector(".pdf-viewer-area");
    const { width } = pdfViewerArea.getBoundingClientRect();
    const containerWidth = Math.round(width);

    // get page
    const page = await pdfDoc.getPage(pageNumber);
    const pageWidth = page.view[2];
    const pageHeight = page.view[3];
    let scaleFactor = 1;

    if (containerWidth > pageWidth) {
      const percentOffset = (containerWidth * 10) / 100; // get 10 percent of the page width
      scaleFactor = (containerWidth - percentOffset) / pageWidth;
    } else {
      const percentOffset = (containerWidth * 20) / 100; // get 20 percent of the page width
      scaleFactor = (containerWidth - percentOffset) / pageWidth;
    }

    currentScaleFactor.current = scaleFactor;

    const viewport = page.getViewport({ scale: scaleFactor + 1 });
    // Prepare canvas using PDF page dimensions.

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    canvas.style.width = `${pageWidth * scaleFactor}px`;
    canvas.style.height = `${pageHeight * scaleFactor}px`;

    // clear the canvas
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);

    // Render PDF page into canvas context.
    const renderContext = { canvasContext, viewport };
    await page.render(renderContext).promise;

    setTimeout(() => {
      if (typeof docRendered === "function") {
        docRendered(currPage);
      }
    }, 100);

    // generate a structure to store pdf data against a page.
    // try and get the value from localdb if data is error or null then add data to localDb
    try {
      const pdfData = await localDb.pdfConfig.get(LOCALSTORAGE_KEYS.pdfData);
      if (pdfData) {
        // check if current page is listed in the localstorage, then add new page if doesn't exist.
        const matchingPdf = pdfData.find((pdf) => pdf?.id === currentPdf?.id);

        if (matchingPdf) {
          // get page data of current pdf
          const pageData = matchingPdf.config.find(
            (data) => data.page === currPage
          );
          if (!pageData) {
            const newPage = {
              page: currPage,
              dataURL: [],
            };

            matchingPdf.config.push(newPage);

            await localDb.pdfConfig.put(pdfData, LOCALSTORAGE_KEYS.pdfData);

            // update the added Widgets list from local db
            setAddedWids(newPage.dataURL);
          } else {
            // update the added Widgets list from local db
            const oldSF = editorRef.current.contents.find(
              (pdf) => pdf.id === currentPdf?.id
            )?.scaleFactor;

            if (!oldScaleFactor.current) {
              oldScaleFactor.current = oldSF;
            }

            let wids = [];

            if (!pageData.dataURL.startsWith("data:image/png;base64")) {
              wids = JSON.parse(pageData.dataURL);
            }

            await loadWidgets(wids);
          }
        } else {
          // add new pdf to localstorage
          const pdfStruct = {
            id: currentPdf.id,
            config: [{ page: currPage, dataURL: [] }],
          };

          pdfData.push(pdfStruct);
          await localDb.pdfConfig.put(pdfData, LOCALSTORAGE_KEYS.pdfData);
        }
      } else {
        // check if any pdf exists in remote.
        const matchingPdfData = editorRef.current?.contents?.find((pdf) => {
          return pdf.id == currentPdf.id;
        });

        if (!oldScaleFactor.current) {
          oldScaleFactor.current = matchingPdfData.scaleFactor;
        }

        if (matchingPdfData?.data) {
          const pdfStruct = [
            {
              id: currentPdf.id,
              config: matchingPdfData?.data,
            },
          ];
          await localDb.pdfConfig.add(pdfStruct, LOCALSTORAGE_KEYS.pdfData);
          // load the widgets to widgets array
          const widgetsRemote = matchingPdfData?.data.find(
            (page) => page.page === currPage
          );

          let widgetRemoteList = [];

          if (!widgetsRemote.dataURL.startsWith("data:image/png;base64")) {
            widgetRemoteList = JSON.parse(widgetsRemote.dataURL);
          }

          await loadWidgets(widgetRemoteList);
        } else {
          // add new structure to database
          const pdfStruct = [
            {
              id: currentPdf.id,
              config: [{ page: currPage, dataURL: [] }],
            },
          ];
          await localDb.pdfConfig.add(pdfStruct, LOCALSTORAGE_KEYS.pdfData);
        }
      }
    } catch (error) {
      console.error(error);
    }

    setIsRendering(false);
    // if any page rendering is pending
    pageRendering = false;
  };

  useEffect(() => {
    (async function () {
      const pdf = await pdfJS.getDocument(currentPdf.filePath).promise;
      setTotalPage(pdf.numPages);
      setPdfDoc(pdf);
      setCurrentPage(1);
    })();

    onOutsideClick();
    if (from === "form") {
      listenKeyPress();
    }
  }, [currentPdf]);

  useEffect(() => {
    if (pdfDoc) {
      queueRenderPage(currPage);
    }

    return () => {
      // clear states
      setWidgets(PDF_WIDGETS);
      setIsRendering(false);
      pageRenderPending = null;
      pageRendering = null;
    };
  }, [currPage, pdfDoc]);

  useEffect(() => {
    if (changePage) {
      setCurrentPage(changePage);
    }
  }, [changePage]);

  useEffect(() => {
    const onlyApprovers = recipients.filter(
      (it) => it.role.role === ROLES.approver
    );
    setApprovers(onlyApprovers);
  }, [recipients]);

  useEffect(() => {
    // set current selected recipient to internal state
    if (approvers.length) {
      setCurrRecipient(approvers[0]);
      setReciBadge(approvers[0].color);
    } else {
      setCurrRecipient(null);
      setReciBadge("#f5f5f5");
    }
  }, [approvers]);

  //save draft and publish button enable / disable block
  useEffect(() => {
    const currUserWids = addedWids.filter(
      (wid) => wid.user === loggedInUserRef.current?.email
    );

    if (currUserWids.length && currUserWids.every((wid) => wid?.signed)) {
      dispatch(designActions.setEditorChanged(true));
    }

    // update widgets count
    dispatch(designActions.setWidgetCount(addedWids.length));

    setTimeout(() => {
      if (!startMousemove) {
        saveData();
      }
    }, 1000);
  }, [addedWids]);

  const queueRenderPage = async (pageNum) => {
    // if page is rendering you put the current page into pending state.
    if (pageRendering) {
      pageRenderPending = pageNum;
    } else {
      // immediately render if no other pages are rendering at the moment.
      await renderPage(pageNum);
    }
  };

  const prevPage = () => {
    if (currPage <= 1) {
      return;
    }
    setCurrentPage(currPage - 1);
    // reset the widgets list
    setAddedWids([]);
  };

  const nextPage = () => {
    if (currPage >= totalPage) {
      return;
    }
    setCurrentPage(currPage + 1);
    // reset the widgets list
    setAddedWids([]);
  };

  const selectWidget = (selectedWidget, event) => {
    event.stopPropagation();

    if (!currRecipient) {
      message.warning("Please add recipients before using widgets.");
      return;
    }

    // apply selected border for the widget
    setWidgets((state) =>
      state.map((tool) => {
        tool.isSelected = tool.value === selectedWidget.value;
        return tool;
      })
    );
    // update the tool
    setTool(selectedWidget.value);

    // add a widget to widget array
    const newWidget = {
      id: uuidv4(),
      type: WIDGET_TYPES[selectedWidget.value],
      x: 0,
      y: 0,
      minWidth: selectedWidget.minWidth,
      width: selectedWidget.width,
      maxWidth: selectedWidget.maxWidth,
      minHeight: selectedWidget.minHeight,
      height: selectedWidget.height,
      maxHeight: selectedWidget.maxHeight,
      signed: false,
      resizable: false,
      hidden: true,
      selected: true,
      name: selectedWidget.label,
      subTitle: selectedWidget.subLabel,
      user: currRecipient.email,
      role: currRecipient.role,
      color: reciBadge,
      value: null,
    };

    setAddedWids([...addedWids, newWidget]);

    // update the selected widget state
    selectedWidgetId.current = newWidget.id;

    // start moues move event
    setStartMousemove(true);

    setIsReadonly(false);
  };

  const onOutsideClick = () => {
    // when clicked outside certain containers then reset all selections and cursor properties.
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".pdf-editor") &&
        !e.target.closest(".tool-props") &&
        !e.target.closest(".pdf-toolbar")
      ) {
        setWidgets((state) =>
          state.map((tool) => {
            tool.isSelected = false;
            return tool;
          })
        );
        setIsReadonly(true);
        setTool(null);
        document.body.style = "cursor: auto;";
      }

      // detect click on widget overlay
      if (!e.target.closest(".widget-content") && addedWids.length) {
        setAddedWids((wids) => {
          return wids.map((widget) => {
            widget.resizable = false;
            widget.selected = false;
            return widget;
          });
        });
        selectedWidgetId.current = null;
      }
    });
  };

  const listenKeyPress = () => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Delete" || e.keyCode === 46 || e.code === "Delete") {
        deleteWidget(selectedWidgetId.current);
      }
    });
  };

  const onMouseEnterToEditor = () => {
    if (addedWids.length && from == "form") {
      setAddedWids((wids) => {
        return wids.map((widget) => {
          if (widget.id == selectedWidgetId.current) {
            widget.hidden = false;
          }
          return widget;
        });
      });
    }
  };

  const onMouseLeaveFromEditor = () => {
    //
  };

  // Empty Property Component
  const EmptyProp = () => {
    return (
      <Empty
        className="empty-props"
        imageStyle={{
          height: 60,
        }}
        description={<span>Choose a widget to see it's properties.</span>}
      ></Empty>
    );
  };

  // Empty Sign Area
  const EmptySignArea = () => {
    return (
      <Empty
        className="empty-props"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            No signatures found.
          </span>
        }
      ></Empty>
    );
  };

  // updates internal state and indexed DB.
  const saveData = async () => {
    try {
      const pdfData = await localDb.pdfConfig.get(LOCALSTORAGE_KEYS.pdfData);

      // update the data in local storage
      const updatedPdfData = pdfData.map((pdf) => {
        if (pdf.id === currentPdf.id) {
          pdf.config = pdf.config.map((page) => {
            if (page.page === currPage) {
              page.dataURL = JSON.stringify(
                addedWids.map((it) => {
                  it.selected = false;
                  it.resizable = false;
                  return it;
                })
              );
            }
            return page;
          });
        }
        return pdf;
      });
      await localDb.pdfConfig.put(updatedPdfData, LOCALSTORAGE_KEYS.pdfData);

      // also update the editor ref
      const updatedContents = editorRef.current.contents.map((content) => {
        const obj = { ...content };
        const matchingPdf = updatedPdfData.find((pdf) => pdf.id === obj.id);
        if (matchingPdf) {
          obj.data = matchingPdf.config;
          if (from == "form" && !obj.scaleFactor) {
            obj.scaleFactor = currentScaleFactor.current;
          }
        }
        return obj;
      });

      // update the editor config
      editorRef.current = {
        ...editorRef.current,
        ...{ contents: updatedContents },
      };
    } catch (error) {
      console.error(error);
    }
  };

  // TODO: Implement Zoom in and Zoom out feature for PDF viewer.
  const stopBubbling = (event) => {
    event.stopPropagation();
  };

  const handleDragStop = (e, dragData) => {
    // update position of widget after drag.
    // TODO: see what happens when u drag element after scrolling down the pdf. (Issue)
    setAddedWids((wids) => {
      return wids.map((widget) => {
        if (widget.id == selectedWidgetId.current) {
          widget = {
            ...widget,
            x: dragData.lastX,
            y: dragData.lastY,
          };
        }
        return widget;
      });
    });
  };

  const handleResizeStop = (e, direction, ref, delta, position) => {
    // update widget width, height and positions after resize.
    setAddedWids((wids) => {
      return wids.map((widget) => {
        if (widget.id == selectedWidgetId.current) {
          widget = {
            ...widget,
            width: widget.width + delta.width,
            height: widget.height + delta.height,
            x: position.x,
            y: position.y,
          };
        }
        return widget;
      });
    });
  };

  const deleteWidget = (widgetId) => {
    // remove the widget from list
    setAddedWids((wids) => {
      return wids.filter((widget) => widget.id !== widgetId);
    });
  };

  const onContextClick = (key) => {
    if (key.key === "del") {
      deleteWidget(selectedWidgetId.current);
    }
  };

  const onContextOpen = (e, selectedWid) => {
    if (e) {
      setAddedWids((wids) => {
        return wids.map((widget) => {
          widget.selected = selectedWid.id == widget.id;
          widget.resizable = selectedWid.id == widget.id;
          return widget;
        });
      });
      selectedWidgetId.current = selectedWid.id;
    }
  };

  const onWidgetClick = (e, selected) => {
    const roleFromUrl = window.atob(searchParams.get("role"));

    if (from == "form" && !hideTools) {
      setAddedWids((wids) => {
        return wids.map((widget) => {
          widget.resizable = widget.id === selected.id;
          widget.selected = widget.id === selected.id;
          return widget;
        });
      });
    } else if (
      from === "doc" &&
      hideTools &&
      ![ROLES.creator, ROLES.receiver].includes(roleFromUrl) &&
      !isReadonly
    ) {
      setAddedWids((wids) => {
        return wids.map((widget) => {
          if (
            widget.id === selected.id &&
            widget.user == loggedInUserRef.current?.email
          ) {
            widget.signed = true;
          }
          return widget;
        });
      });
    }
    selectedWidgetId.current = selected.id;
  };

  const handleSignConfig = (e) => {
    e.preventDefault();
    navigate("/signatures");
  };

  const handleTextChange = (e, selected) => {
    e.stopPropagation();
    e.preventDefault();
    setAddedWids((wids) => {
      return wids.map((widget) => {
        if (widget.id === selected.id) {
          widget.value = {
            type: "string",
            data: e.target.value,
          };
        }
        return widget;
      });
    });
  };

  const handleTextBlur = (e) => {
    e.target.scrollLeft = 0;
  };

  // method which renders each widget to ui
  const renderWidgets = (widget) => {
    let yetConfig = (
      <div className="signature-not-config" onClick={handleSignConfig}>
        <img src="/assets/exclamation-mark.svg" />
        <p>Signature not set, click to configure</p>
      </div>
    );
    let content = null;

    switch (widget.type) {
      case WIDGET_TYPES.signature:
        if (widget?.signatureName) {
          content = (
            <SignatureNameComponent
              name={widget.signatureName}
              font={widget.signatureFont}
              serial={widget.signatureSerial}
            ></SignatureNameComponent>
          );
        } else if (widget?.signImg) {
          content = (
            <img
              style={{ width: "50px", height: "50px" }}
              src={widget.signImg}
            />
          );
        } else {
          content = yetConfig;
        }
        break;
      case WIDGET_TYPES.initials:
        if (widget?.signatureName) {
          content = (
            <SignatureInitialComponent
              initials={widget.signatureInitials}
              font={widget.signatureFont}
              serial={widget.signatureSerial}
            ></SignatureInitialComponent>
          );
        } else if (widget?.signImg) {
          content = (
            <img
              style={{ width: "50px", height: "50px" }}
              src={widget.signImg}
            />
          );
        } else {
          content = yetConfig;
        }
        break;
      case WIDGET_TYPES.signDate:
        content = <p>{moment().format("DD-MM-YY")}</p>;
        break;
      case WIDGET_TYPES.text:
        content = (
          <input
            placeholder={`${widget.name} ${widget.subTitle}`}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              padding: "5px",
              scrollBehavior: "smooth",
              outline: "none",
              borderRadius: "4px",
              border: "1px solid #cfcfcf",
              transition: "all 0.3s ease-in-out",
            }}
            value={widget.value?.data}
            onChange={(e) => handleTextChange(e, widget)}
            onBlur={handleTextBlur}
          />
        );
        break;
    }

    return content;
  };

  const recipientChanged = (change) => {
    // set current selected recipient to internal state
    const matchingRecipient = approvers.find((it) => it.email === change);
    if (matchingRecipient) {
      setCurrRecipient(matchingRecipient);
      setReciBadge(matchingRecipient.color);
    }
  };

  return (
    <div className="pdf-viewer-container">
      {!isMenuCollapsed ? (
        <div className="pdf-toolbar">
          <Divider orientation="left">Page Navigation</Divider>
          <div className="page-nav">
            <Tooltip title="Previous Page">
              <Button onClick={prevPage} id="pdf-prev-btn">
                <LeftOutlined />
              </Button>
            </Tooltip>
            <p>
              {currPage} / {totalPage}
            </p>
            <Tooltip title="Next Page" id="pdf-next-btn">
              <Button onClick={nextPage}>
                <RightOutlined />
              </Button>
            </Tooltip>
          </div>
          {!hideTools ? (
            <>
              {currRecipient ? (
                <>
                  <Divider orientation="left">Recipient</Divider>
                  <div className="shown-reci">
                    <Badge color={reciBadge} style={{ margin: "5px" }} />
                    <Select
                      style={{ width: "calc(100% - 20px)" }}
                      onChange={recipientChanged}
                      disabled={!approvers.length}
                      placeholder={
                        approvers.length ? "Choose Recipients" : "No Recipients"
                      }
                      options={
                        approvers.length
                          ? [...approvers].map((it) => {
                              return {
                                label: it.email,
                                value: it.email,
                              };
                            })
                          : []
                      }
                      defaultValue={currRecipient?.email}
                    ></Select>
                  </div>
                </>
              ) : null}
              <Divider orientation="left">Tools</Divider>
              <div className="page-widgets" onClick={stopBubbling}>
                <Space>
                  {widgets.map((tool) => {
                    return (
                      <Tooltip title={tool.label} key={tool.value}>
                        <img
                          className={
                            tool.isSelected
                              ? "pdf-tool-selected tool-icons"
                              : "tool-icons"
                          }
                          src={tool.icon}
                          onClick={(e) => selectWidget(tool, e)}
                        />
                      </Tooltip>
                    );
                  })}
                </Space>
              </div>
              <Divider orientation="left">Properties</Divider>
              <div className="tool-props" onClick={stopBubbling}>
                <Space>{!tool && <EmptyProp />}</Space>
              </div>
            </>
          ) : from === "doc" ? (
            <>
              <Divider orientation="left">Your Sign Areas</Divider>
              <div className="shown-reci designated-boxes">
                {addedWids.length ? (
                  <Badge
                    color={
                      addedWids?.find(
                        (widget) =>
                          widget.user == loggedInUserRef.current?.email
                      )?.color
                    }
                    style={{ margin: "5px" }}
                  />
                ) : (
                  <EmptySignArea />
                )}
              </div>
            </>
          ) : null}
        </div>
      ) : null}

      <div className="pdf-viewer-area" style={{ position: "relative" }}>
        <div
          className="bingo"
          style={{
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <canvas
            id="hidden-canvas-editor"
            ref={canvasRef}
            style={{ width: "100%", height: "100%" }}
          />
          {!isRendering ? (
            <div
              className="pdf-editor"
              onMouseEnter={onMouseEnterToEditor}
              onMouseLeave={onMouseLeaveFromEditor}
              style={{
                width: canvasRef.current?.clientWidth,
                height: canvasRef.current?.clientHeight,
              }}
            >
              <div
                className="widget-overlay"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
              >
                {addedWids?.map((widget) => {
                  let scaledWidth = widget.width;
                  let scaledHeight = widget.height;
                  let minWidth = widget.minWidth;
                  let minHeight = widget.minHeight;
                  let scaledX = widget.x;
                  let scaledY = widget.y;

                  if (oldScaleFactor.current && currentScaleFactor.current) {
                    // before applying scaled width reduce minWidth property
                    minWidth = 10;
                    scaledWidth *=
                      currentScaleFactor.current / oldScaleFactor.current;
                    // before applying scaled height reduce minHeight property
                    minHeight = 10;
                    scaledHeight *=
                      currentScaleFactor.current / oldScaleFactor.current;
                    // apply scaled x and y coordinates
                    scaledX =
                      (widget.x / oldScaleFactor.current) *
                      currentScaleFactor.current;

                    scaledY =
                      (widget.y / oldScaleFactor.current) *
                      currentScaleFactor.current;
                  }

                  return (
                    <div
                      className="widget-content-wrap"
                      key={widget.id}
                      style={{ display: widget.hidden ? "none" : "flex" }}
                      onClick={(e) => onWidgetClick(e, widget)}
                    >
                      <Dropdown
                        trigger={["contextMenu"]}
                        disabled={from == "doc" && hideTools}
                        onOpenChange={(e) => onContextOpen(e, widget)}
                        menu={{
                          items: WIDGET_CONTEXT_MENU,
                          onClick: onContextClick,
                        }}
                      >
                        <Rnd
                          id={widget.id}
                          ref={rndRef}
                          bounds={".widget-overlay"}
                          className="widget-content"
                          style={{
                            backgroundColor: !widget.signed
                              ? widget.color
                              : "transparent",
                          }}
                          default={{ x: 0, y: 0 }}
                          size={{
                            width: scaledWidth,
                            height: scaledHeight,
                          }}
                          position={{
                            x: scaledX,
                            y: scaledY,
                          }}
                          minWidth={minWidth}
                          maxWidth={widget.maxWidth}
                          minHeight={minHeight}
                          maxHeight={widget.maxHeight}
                          onDragStop={(e, d) => handleDragStop(e, d)}
                          onResizeStop={(e, direction, ref, delta, position) =>
                            handleResizeStop(e, direction, ref, delta, position)
                          }
                          enableResizing={widget.resizable}
                          disableDragging={!widget.resizable}
                          resizeHandleStyles={RESIZE_HANDLES_STYLES}
                        >
                          {widget.signed ? (
                            renderWidgets(widget)
                          ) : (
                            <>
                              <p style={{ fontSize: 12 }}>
                                {widget.name} {widget.subTitle}
                              </p>
                              <img src="/assets/tools/arrow-down.svg" />
                            </>
                          )}
                        </Rnd>
                      </Dropdown>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <Skeleton.Node active={true} style={{ width: 300, height: 350 }}>
              <FilePdfOutlined
                style={{
                  fontSize: 100,
                  color: "#bfbfbf",
                }}
              />
            </Skeleton.Node>
          )}
        </div>
      </div>
    </div>
  );
};

export default PdfViewerComponent;
