import { Button, Modal, Typography } from 'antd';
import { FLOW_TYPE } from '../../constant/constants';

const { Paragraph } = Typography;
const FlowDialogComponent = ({ modalState, newForm }) => {

    const handleCancel = () => {
        modalState.setIsOpen(false);
    }

    const afterModalClose = () => {
        //
    }


    return (
        <Modal
            title={''}
            centered
            open={modalState.isOpen}
            onCancel={handleCancel}
            afterClose={afterModalClose}
            maskClosable={false}
            footer={[]}
        >
            <Paragraph strong>Choose from below</Paragraph>
            <div className="flow-type-container">
                <Button type='default' onClick={() => {
                    newForm(FLOW_TYPE.design);
                }}>Design Yourself</Button>
                <Button type='default' onClick={() => {
                    newForm(FLOW_TYPE.upload);
                }}>Upload Document</Button>
            </div>
        </Modal>
    )
}

export default FlowDialogComponent;