const SignatureInitialComponent = ({ initials, font, serial }) => {
  return (
    <div className="sign-name-wrap sign-initial-wrap">
      <div className="sign-content-wrap">
        <div className="signed-by">
          <div className="signed-by-lock">
            <img src="/assets/locked.png"></img>
            <span>Signed By:</span>
          </div>
          <div className="border-rs-vert">
            <span></span>
          </div>
        </div>
        <div className="sign-name">
          <p style={{ fontFamily: font }}>{initials}</p>
        </div>
        <div className="sign-serial">
          <div className="serial">
            {serial?.replaceAll("-", "")?.toUpperCase()}
          </div>
          <div className="border-rs-hz">
            <span></span>
          </div>
        </div>
      </div>
      <div className="sign-border-curve">
        <span></span>
      </div>
    </div>
  );
};

export default SignatureInitialComponent;
