import { v4 as uuidv4 } from 'uuid';
import { ListClassNames } from './inline-toolbars/list-inline';
import './table.css';

// default border style values.
const defaultBorderStyles = {
    show: false,
    width: 1,
    color: 'transparent',
    style: 'solid',
    radius: 0,
    spacing: 0
}

const closeBtnSvg = `<svg width="24px" height="24px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg>`;

// class names mapper
const classNames = {
    table: 'tc-table',
    tableCell: 'tc-table-cell',
    contextMenu: 'tc-table-context-menu',
    menuItem: 'tc-menu-item',
    overlay: 'tc-overlay',
    dialogCont: 'tc-dialog-container',
    dialogHead: 'tc-dialog-head',
    dialogFooter: 'tc-dialog-footer',
    dialogContent: 'tc-dialog-content',
    dialogInpCont: 'tc-dialog-content-inp',
    fontColorLabel: 'tc-dialog-content-inp-label',
    pickerCont: 'tc-picker-cont',
    cancelBtn: 'tc-cancel-btn',
    applyBtn: 'tc-apply-btn',
    cellSelected: 'cell-selected'
}

// context menu id as enum 
const CONTEXT_MENU_IDS = Object.freeze({
    styleChange: 'styleChange',
    mergeCell: 'mergeCell'
});

// context menus constant
const contextMenus = [
    {
        id: CONTEXT_MENU_IDS.styleChange,
        name: 'Change Style',
        data: ''
    },
    {
        id: CONTEXT_MENU_IDS.mergeCell,
        name: 'Merge Cells',
        data: ''
    }
]


export default class TablePlugin {

    constructor({ data, config, api, readOnly }) {
        this.table = null;
        this.data = data;
        this.config = config;
        this.readOnly = readOnly;
        this.api = api;
        this.settings = [
            {
                name: 'addColumn',
                label: 'Add Column',
                icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h3M3 21h3m0 0h4a2 2 0 0 0 2-2V9M6 21V9m0-6h4a2 2 0 0 1 2 2v4M6 3v6M3 9h3m0 0h6m-9 6h9m3-3h3m0 0h3m-3 0v3m0-3V9"/></svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.addColumn();
                }
            },
            {
                name: 'addColumnAfter',
                label: 'Add Column After',
                icon: `<svg width="24" height="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" mirror-in-rtl="true">
                <path fill="#494c4e" d="M18 15a1 1 0 0 1-1 1h-1v1a1 1 0 0 1-2 0v-1h-1a1 1 0 0 1 0-2h1v-1a1 1 0 0 1 2 0v1h1a1 1 0 0 1 1 1zM16 2v6h-2V2h2zm1-2h-4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM9 0H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM4 16H2V2h2v14zm4 0H6V2h2v14z"/>
              </svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.addColumnAfter();
                }
            },
            {
                name: 'addColumnBefore',
                label: 'Add Column Before',
                icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h3M3 21h3m0 0h4a2 2 0 0 0 2-2V9M6 21V9m0-6h4a2 2 0 0 1 2 2v4M6 3v6M3 9h3m0 0h6m-9 6h9m3-3h3m0 0h3m-3 0v3m0-3V9"/></svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.addColumnBefore();
                }
            },
            {
                name: 'removeColumn',
                label: 'Remove Column',
                icon: `<svg fill="#000000" width="24" height="24" viewBox="0 0 1024 1024" t="1569683386799" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10077" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><style type="text/css"></style></defs><path d="M651.1 641.9c-1.4-1.2-3.2-1.9-5.1-1.9h-54.7c-2.4 0-4.6 1.1-6.1 2.9L512 730.7l-73.1-87.8c-1.5-1.8-3.8-2.9-6.1-2.9H378c-1.9 0-3.7 0.7-5.1 1.9-3.4 2.8-3.9 7.9-1 11.3L474.2 776 371.8 898.9c-2.8 3.4-2.4 8.4 1 11.3 1.4 1.2 3.2 1.9 5.1 1.9h54.7c2.4 0 4.6-1.1 6.1-2.9l73.1-87.8 73.1 87.8c1.5 1.8 3.8 2.9 6.1 2.9h55c1.9 0 3.7-0.7 5.1-1.9 3.4-2.8 3.9-7.9 1-11.3L549.8 776l102.4-122.9c2.8-3.4 2.3-8.4-1.1-11.2zM472 544h80c4.4 0 8-3.6 8-8V120c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8v416c0 4.4 3.6 8 8 8zM350 386H184V136c0-3.3-2.7-6-6-6h-60c-3.3 0-6 2.7-6 6v292c0 16.6 13.4 30 30 30h208c3.3 0 6-2.7 6-6v-60c0-3.3-2.7-6-6-6zM906 130h-60c-3.3 0-6 2.7-6 6v250H674c-3.3 0-6 2.7-6 6v60c0 3.3 2.7 6 6 6h208c16.6 0 30-13.4 30-30V136c0-3.3-2.7-6-6-6z" p-id="10078"></path></svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.removeColumn();
                }
            },
            {
                name: 'addRow',
                label: 'Add Row',
                icon: `<svg width="24" height="24" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <rect width="16" height="16" id="icon-bound" fill="none" />
                <path id="table-add-row" d="M11,12l2,0l0,2l-2,0l0,2l-2,0l0,-2l-2,0l0,-2l2,0l0,-2l2,0l0,2Zm-5,2l-5,-0c-0.265,0 -0.52,-0.105 -0.707,-0.293c-0.188,-0.187 -0.293,-0.442 -0.293,-0.707l-0,-10c-0,-0.552 0.448,-1 1,-1c2.577,0 11.423,-0 14,0c0.552,0 1,0.448 1,1c-0,1.916 0,8.084 -0,10c-0,0.552 -0.448,1 -1,1l-1,-0l0,-5l-12,-0l0,3l4,0l0,2Zm-4,-10l0,3l12,0l0,-3l-12,0Z" />
              </svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.addRow();
                }
            },
            {
                name: 'rowRemove',
                label: 'Remove Row',
                icon: `<svg width="24" height="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" mirror-in-rtl="true">
                <path fill="#494c4e" d="M17 0H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h10.02a5.064 5.064 0 0 1-.9-2H2v-2h8.1a4.944 4.944 0 0 1 .923-2H2v-2h13a4.98 4.98 0 0 1 3 1.02V1a1 1 0 0 0-1-1zm-1 8H2V6h14v2zm0-4H2V2h14v2z"/>
                <path fill="#494c4e" d="M13 14h4a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1z"/>
              </svg>`,
                toggle: 'list',
                closeOnActivate: true,
                onActivate: (tune) => {
                    this.removeRow();
                }
            }
        ];
        this.windowClick();
    }

    static get isReadOnlySupported() {
        return true;
    }

    // renders the tool box icon.
    static get toolbox() {
        return {
            title: 'Table',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-columns" viewBox="0 0 16 16"> <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2zm8.5 0v8H15V2H8.5zm0 9v3H15v-3H8.5zm-1-9H1v3h6.5V2zM1 14h6.5V6H1v8z"/> </svg>'
        }
    }

    /**
   * Prevents default Enter behaviors
   * Adds Shift+Enter processing
   *
   * @param {KeyboardEvent} event - keypress event
   */
    onKeyPressListener(event) {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if (event.shiftKey) {
                return true;
            }
            event.stopPropagation();
        }
        return event.key !== 'Enter';
    };


    // utility method for merging border styles.
    resolveBorderStyles(border) {
        // merge default and user configured border styles.
        if (!Object.isExtensible(border)) {
            border = {};
        }
        const borderStyle = {
            ...defaultBorderStyles,
            ...border
        }
        // if border is enabled apply border styles.
        const styleString = border.show ? ` border: ${borderStyle.style} ${borderStyle.color} ${borderStyle.width}px; border-radius: ${borderStyle.radius}px;` : '';
        return styleString;
    }

    generateStructure(rows = 4, columns = 4, cellPadding = 4, border) {
        // generate rows
        const row_arr = [];
        for (let i = 0; i < rows; i++) {
            const col_arr = [];

            // generate cols 
            for (let j = 0; j < columns; j++) {
                col_arr[j] = {
                    text: '',
                    styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                    id: `${i}-${j}`,
                    colspan: 1,
                    rowspan: 1
                };
            }

            row_arr[i] = col_arr;
        }

        return row_arr;
    }

    getFocusedTableAndParent() {
        const selectedCell = document.querySelector(`.${classNames.cellSelected}`);
        const parentTable = selectedCell?.offsetParent;
        if (!parentTable) {
            const blockIndex = this.api.blocks.getCurrentBlockIndex();
            const block = this.api.blocks.getBlockByIndex(blockIndex);
            return {
                parent: block.holder.firstChild,
                table: block.holder.firstChild.firstChild
            }
        }

        return {
            parent: parentTable.parentElement,
            table: parentTable
        }
    }

    updateView() {
        const { parent, table: oldTable } = this.getFocusedTableAndParent();
        const newTable = this.generateTable(this.data);
        newTable.id = oldTable.id;
        parent.replaceChild(newTable, oldTable);
    }

    extractData() {
        const { _, table } = this.getFocusedTableAndParent();
        return this.data.map(row => {
            const rowUpdated = row.map(col => {
                const colObj = { ...col };
                const xpath = `.//node()[@id='${colObj.id}']`;
                const matchingElem = window.document.evaluate(xpath, table, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                if (matchingElem) {
                    colObj.text = matchingElem.textContent;
                    colObj.styles = matchingElem.style.cssText;
                }

                if (matchingElem?.hasChildNodes()) {
                    const ulElements = Array.from(matchingElem.childNodes).filter(it => it.classList?.contains(ListClassNames.ul));
                    if (ulElements.length) {
                        const liElements = Array.from(ulElements[0]?.children).map(it => it.textContent);
                        colObj.text = liElements;
                    }
                }

                return colObj;
            });
            return rowUpdated;
        });
    }

    getColumnIndex(cellId) {
        return cellId ? cellId.split('-')[1] * 1 : null;
    }

    getRowIndex(cellId) {
        return cellId ? cellId.split('-')[0] * 1 : null;
    }

    addColumn() {
        const { cellPadding, border } = this.config;
        const dataExtracted = this.extractData();
        this.data = dataExtracted.map((row, i) => {
            row.push({
                text: '',
                styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                id: `${i}-${row.length}`,
                colspan: 1,
                rowspan: 1
            });
            return row;
        });
        this.updateView();
    }

    addColumnAfter() {
        const dataExtracted = this.extractData();
        const { cellPadding, border } = this.config;
        const selectedCell = document.querySelector(`.${classNames.cellSelected}`);
        if (selectedCell?.id) {
            // calculate the next index and add empty column object.
            const selectedColIndex = this.getColumnIndex(selectedCell.id) > -1 ? this.getColumnIndex(selectedCell.id) : dataExtracted[0].length - 1;
            const nextColumnIndex = selectedColIndex + 1;
            // reiterate over the data array and adjust the indexes accordingly.
            const updatedData = dataExtracted.map((row, r_index) => {

                // add the new colum at next index.
                const columnObj = {
                    text: '',
                    styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                    id: `${r_index}-${nextColumnIndex}`,
                    colspan: 1,
                    rowspan: 1
                }
                row.splice(nextColumnIndex, 0, columnObj);
                // update the indexes
                const updatedRow = row.map((col, c_index) => {
                    if (c_index > nextColumnIndex) {
                        col.id = `${r_index}-${this.getColumnIndex(col.id) + 1}`;
                    }
                    return col;
                });

                return updatedRow;
            });

            // update data array
            this.data = updatedData;

            // then call replace table to update the view
            this.updateView();
        } else {
            // proceed by adding column at last index
            this.addColumn();
        }
    }

    addColumnBefore() {
        const dataExtracted = this.extractData();
        const { cellPadding, border } = this.config;
        const selectedCell = document.querySelector(`.${classNames.cellSelected}`);
        if (selectedCell?.id) {
            // calculate the previous index and add empty column object.
            const selectedColIndex = this.getColumnIndex(selectedCell.id) > -1 ? this.getColumnIndex(selectedCell.id) : 0;
            const updatedStruct = dataExtracted.map((row, r_index) => {

                // add the new colum at next index.
                const columnObj = {
                    text: '',
                    styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                    id: `${r_index}-${selectedColIndex}`,
                    colspan: 1,
                    rowspan: 1
                }
                row.splice(selectedColIndex, 0, columnObj);
                // update the indexes
                const updatedRow = row.map((col, c_index) => {
                    if (c_index > selectedColIndex) {
                        col.id = `${r_index}-${this.getColumnIndex(col.id) + 1}`;
                    }
                    return col;
                });

                return updatedRow;
            });

            this.data = updatedStruct;
            this.updateView();
        }
    }

    removeColumn() {
        this.data = this.data.map((row, i) => {
            row.pop();
            return row;
        });
        this.updateView();
    }

    calculateOriginalCols = (arrayOfNumbers) => {
        let track = [];
        arrayOfNumbers.forEach(it => {
            const onlyItems = track.map(item => item.item);
            if (onlyItems.includes(it)) {
                const updatedTrack = track.map(item => {
                    if (item.item === it) {
                        item.count++;
                    }
                    return item;
                });
                track = updatedTrack;
            } else {
                track.push({
                    item: it,
                    count: 1
                });
            }
        });
        const mostOccurrence = track.sort((a, b) => a.count - b.count);
        return mostOccurrence.pop();
    };

    addRow() {
        const { cellPadding, border } = this.config;
        const dataExtracted = this.extractData();
        // get last row and update it to new row
        const newRow = [];
        const originalColsLength = this.calculateOriginalCols(dataExtracted.flatMap(row => row.length))?.item;

        for (let i = 0; i < originalColsLength; i++) {
            newRow.push({
                text: '',
                styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                id: `${this.data.length}-${i}`,
                colspan: 1,
                rowspan: 1
            });
        }

        dataExtracted.push(newRow);
        this.data = dataExtracted;
        this.updateView();
    }

    removeRow() {
        // find the selected row and remove it.
        const dataExtracted = this.extractData();
        const selectedCell = document.querySelector(`.${classNames.cellSelected}`);
        const rowIndex = this.getRowIndex(selectedCell?.id) || dataExtracted.length - 1;
        dataExtracted.splice(rowIndex, 1);
        this.data = dataExtracted.map((row, r_index) => {
            return row.map((col, c_index) => {
                if (r_index >= rowIndex) {
                    col = {
                        ...col,
                        id: `${r_index}-${c_index}`
                    };
                }
                return col;
            });
        });

        // then call replace table to update the view
        this.updateView();
    }

    windowClick() {
        window.addEventListener('click', (event) => {
            const insideOverlay = event.target.closest(`.${classNames.overlay}`);
            const insideToolbar = event.target.closest('[class*="ce-"]');
            if (!insideOverlay && !insideToolbar) {
                this.removeContextMenus();
                // clear all cell selections
                const allCellsSelected = document.querySelectorAll(`.${classNames.cellSelected}`);
                allCellsSelected.forEach(it => {
                    it.classList.remove(`${classNames.cellSelected}`);
                });
            }
        });
    }

    removeContextMenus() {
        const allContextMenus = document.querySelectorAll(`.${classNames.contextMenu}`);
        Array.from(allContextMenus).every(it => it.remove());
    }

    closeOverlay() {
        this.removeContextMenus();
        const overlay = document.querySelector(`.${classNames.overlay}`);
        overlay.remove();
    }

    tableStyleDialog(menuItem) {
        // overlay container
        const overlay = document.createElement('section');
        overlay.classList.add(classNames.overlay);

        // dialog container 
        const dialogContainer = document.createElement('div');
        dialogContainer.classList.add(classNames.dialogCont);

        // head container 
        const dialogHeader = document.createElement('header');
        dialogHeader.classList.add(classNames.dialogHead);
        const headerPara = document.createElement('p');
        headerPara.textContent = 'Change Style'
        const closeBtn = document.createElement('button');
        closeBtn.innerHTML = closeBtnSvg;

        // close button click event binding 
        closeBtn.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.closeOverlay();
        });
        dialogHeader.append(headerPara, closeBtn);

        // main container 
        const dialogContent = document.createElement('main');
        dialogContent.classList.add(classNames.dialogContent);

        // create font input container element 
        const inpContainer = document.createElement('div');
        inpContainer.classList.add(classNames.dialogInpCont);

        // font color container 
        const fontColorLabel = document.createElement('p');
        fontColorLabel.classList.add(classNames.fontColorLabel);
        fontColorLabel.textContent = 'Font Color'

        // font input container 
        const fontInpContainer = document.createElement('div');
        fontInpContainer.classList.add(classNames.pickerCont);

        const fontColorPara = document.createElement('p');
        fontColorPara.style = 'margin: 0;';
        fontColorPara.textContent = '#000000';

        const fontColorInp = document.createElement('input');
        fontColorInp.setAttribute('type', 'color');
        fontColorInp.addEventListener('change', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.target.value) {
                fontColorPara.textContent = event.target.value;
            }
        });
        fontInpContainer.append(fontColorPara, fontColorInp);
        inpContainer.append(fontColorLabel, fontInpContainer);

        // create font input container element 
        const bgContainer = document.createElement('div');
        bgContainer.classList.add(classNames.dialogInpCont);

        // font color container 
        const bgColorLabel = document.createElement('p');
        bgColorLabel.classList.add(classNames.fontColorLabel);
        bgColorLabel.textContent = 'Background Color'

        // bg input container 
        const bgInpContainer = document.createElement('div');
        bgInpContainer.classList.add(classNames.pickerCont);

        const bgColorPara = document.createElement('p');
        bgColorPara.style = 'margin: 0;';
        bgColorPara.textContent = '#000000';

        const bgColorInp = document.createElement('input');
        bgColorInp.setAttribute('type', 'color');
        bgColorInp.addEventListener('change', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.target.value) {
                bgColorPara.textContent = event.target.value;
            }
        });
        bgInpContainer.append(bgColorPara, bgColorInp);

        bgContainer.append(bgColorLabel, bgInpContainer);

        dialogContent.append(inpContainer, bgContainer);

        // create footer element for buttons
        const dialogFooter = document.createElement('footer');
        dialogFooter.classList.add(classNames.dialogFooter);

        const cancelBtn = document.createElement('button');
        cancelBtn.classList.add(classNames.cancelBtn);
        cancelBtn.textContent = 'cancel';
        cancelBtn.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.closeOverlay();
        });

        const applyBtn = document.createElement('button');
        applyBtn.textContent = 'apply changes';
        applyBtn.classList.add(classNames.applyBtn);
        applyBtn.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            const [fontColor, bgColor] = [fontColorPara.textContent, bgColorPara.textContent];
            // find the cell and update the cell styles
            const selectedCells = Array.from(document.querySelectorAll(`.${classNames.cellSelected}`)).map(it => it.id);
            if (selectedCells.length) {
                const dataExtracted = this.extractData();
                dataExtracted.forEach(() => {
                    const id = selectedCells.pop();
                    if (id) {
                        const rowIndex = this.getRowIndex(id);
                        const colIndex = this.getColumnIndex(id);
                        dataExtracted[rowIndex][colIndex].styles += ` background-color: ${bgColor}; color: ${fontColor};`;
                    }
                });
                this.data = dataExtracted;
                this.updateView();
            }
            this.closeOverlay();
        });

        dialogFooter.append(cancelBtn, applyBtn);

        dialogContainer.append(dialogHeader, dialogContent, dialogFooter);

        overlay.appendChild(dialogContainer);
        document.body.appendChild(overlay);
    }

    mergeCells() {
        const { cellPadding, border } = this.config;
        const selectedCells = document.querySelectorAll(`.${classNames.cellSelected}`);
        // extract id from the elements
        const ids = Array.from(selectedCells).map(it => it.id);
        const rowIds = ids.map(id => this.getRowIndex(id));
        const colIds = ids.map(id => this.getColumnIndex(id));

        // verify row ids or col ids to be merged.
        // check row ids for column span
        const rowCheck = rowIds.every((id, _, self) => id === self[0]);
        // check col ids for row span
        const colCheck = colIds.every((id, _, self) => id === self[0]);

        // disable merging if both col and row is able to merge.
        if (rowCheck && colCheck) {
            this.removeContextMenus();
            return;
        }

        const dataExtracted = this.extractData();

        if (rowCheck) {
            // proceed for column span
            this.data = dataExtracted.map((row, r_index) => {
                if (r_index === rowIds[0]) {
                    const oldCol = row[colIds[0]];
                    const newCol = {
                        text: oldCol.text,
                        styles: `padding: ${cellPadding}px; ${this.resolveBorderStyles(border)};`,
                        id: '',
                        colspan: rowIds.length,
                        rowspan: 1
                    };
                    row.splice(colIds[0], colIds.length, newCol);
                }

                // adjust the indexes
                const updatedRow = row.map((col, c_index) => {
                    col.id = `${r_index}-${c_index}`;
                    return col;
                });
                return updatedRow;
            });

            this.updateView();
        }

        if (colCheck) {
            this.data = dataExtracted.map((row, r_index) => {
                if (rowIds.includes(r_index)) {
                    let updatedRow = row.map((col, c_index) => {
                        if (c_index === colIds[0]) {
                            if (r_index === rowIds[0]) {
                                const newCol = {
                                    ...col,
                                    rowspan: rowIds.length
                                }
                                return newCol;
                            }
                        }
                        return col;
                    });

                    // adjust the indexes.
                    updatedRow.map((_, c_index, self) => {
                        if (c_index === colIds[0]) {
                            if (r_index !== rowIds[0]) {
                                self.splice(colIds[0], 1);
                            }
                        }
                    });

                    return updatedRow;
                }
                return row;
            });
            this.updateView();
        }

        this.removeContextMenus();

    }

    generateContextMenu({ x, y }) {
        this.removeContextMenus();
        const contextMenu = document.createElement('div');
        const menuItems = contextMenus.map(item => {
            const pElem = document.createElement('p');
            pElem.textContent = item.name;
            pElem.classList.add(classNames.menuItem);
            pElem.setAttribute('id', item.id);
            pElem.addEventListener('click', event => {
                event.stopPropagation();
                if (item.id === CONTEXT_MENU_IDS.styleChange) {
                    this.tableStyleDialog(item);
                } else {
                    this.mergeCells();
                }
            });
            return pElem;
        });
        contextMenu.classList.add(classNames.contextMenu);
        contextMenu.append(...menuItems);
        // apply context menu styles
        contextMenu.style.left = `${x}px`;
        contextMenu.style.top = `${y}px`;
        document.body.appendChild(contextMenu);
    }

    generateTable(structure) {
        const { border } = this.config;
        const table = document.createElement('table');
        table.style = `border-spacing: ${border.spacing}px; border-radius: ${border.radius}px;`;
        table.classList.add(classNames.table);
        const tableBody = document.createElement('tbody');
        structure.forEach(row => {
            const tableRow = document.createElement('tr');
            row.forEach(col => {
                const tableCol = document.createElement('td');
                tableCol.style = col.styles;
                tableCol.classList.add(classNames.tableCell);
                // create a text node for each table cell
                let childNode;
                if (Array.isArray(col.text)) {
                    childNode = document.createElement('ul');
                    childNode.classList.add(ListClassNames.ul);
                    col.text.forEach(it => {
                        const li = document.createElement('li');
                        li.classList.add(ListClassNames.ulItem);
                        const textNode = document.createTextNode(it);
                        li.appendChild(textNode);
                        childNode.appendChild(li);
                    });
                } else {
                    childNode = document.createTextNode(`${col.text}`);
                }

                tableCol.appendChild(childNode);
                tableCol.setAttribute('contentEditable', !this.readOnly);
                tableCol.setAttribute('id', col.id);
                tableCol.setAttribute('rowspan', col.rowspan);
                tableCol.setAttribute('colspan', col.colspan);
                tableRow.appendChild(tableCol);

                // bind events if its not readonly mode
                if (!this.readOnly) {
                    // bind click listener for the cell 
                    tableCol.addEventListener('click', (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        event.target.classList.add(classNames.cellSelected);
                    });
                    tableCol.onkeydown = (event) => this.onKeyPressListener(event);
                    // bind context listener for the cell
                    tableCol.addEventListener('contextmenu', (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        event.target.classList.add(classNames.cellSelected);
                        this.generateContextMenu({ x: event.clientX, y: event.clientY });
                    });
                }

            });
            tableBody.appendChild(tableRow);
        });
        table.appendChild(tableBody);
        return table;
    }

    // render method which renders table content.
    render() {
        const { rows, cols, cellPadding, border } = this.config;
        // generate data structure before we generate actual dom elements.
        const structure = Object.keys(this.data).length ? Object.values(this.data) : this.generateStructure(rows, cols, cellPadding, border);
        this.data = structure;
        // generate table using the structure we created
        this.table = this.generateTable(structure);
        this.table.id = uuidv4();
        return this.table;
    }

    // save method which gets triggered after calling `editor.save` method
    save(blockContent) {
        this.data = this.extractData();
        return {
            ...this.data
        }
    }

    // render setting method to tune table cells and styles.
    renderSettings() {
        return this.settings;
    }

}