import { AuthService } from "../components/auth/services/auth.service";

export const newUser = (values) => {
    return async () => {
        try {
            return await (await AuthService.registerUser(values)).json();
        } catch (error) {
            console.error('error registering user', error);
            return null;
        }
    }
}

export const login = (values) => {
    return async () => {
        try {
            return await (await AuthService.signInUser(values)).json();
        } catch (error) {
            console.error('error signing in user', error);
            return null;
        }
    }
}

export const verify = (values, verifyType) => {
    return async () => {
        try {
            return await (await AuthService.verifyUser(values, verifyType)).json();
        } catch (error) {
            console.error('error verifying user', error);
            return null;
        }
    }
}

export const forgotPass = (payload) => {
    return async () => {
        try {
            return await (await AuthService.forgotPass(payload)).json();
        } catch (error) {
            console.error('error resetting password', error);
            return null;
        }
    }
}

export const verifyEmail = (payload) => {
    return async () => {
        try {
            return await (await AuthService.verifyEmail(payload)).json();
        } catch (error) {
            console.error('error verifying email', error);
            return null;
        }
    }
}

export const resetPass = (payload) => {
    return async () => {
        try {
            return await (await AuthService.resetPass(payload)).json();
        } catch (error) {
            console.error('error resetting password', error);
            return null;
        }
    }
}