import {
  CloseCircleOutlined,
  FilePdfOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import EditorJS from "@editorjs/editorjs";
import {
  Button,
  Empty,
  Modal,
  Progress,
  Space,
  Typography,
  message,
} from "antd";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import ConfirmDialog from "../../common/confirm-dialog";
import ListPlugin from "../../common/plugins/inline-toolbars/list-inline";
import ParagraphPlugin from "../../common/plugins/paragraph-tool/paragraph-tool";
import TablePlugin from "../../common/plugins/table";
import { tokenValidator } from "../../common/token-validator";
import {
  BASE_URL,
  FLOW_TYPE,
  LOCALSTORAGE_KEYS,
} from "../../constant/constants";
import { localDb } from "../../services/dexie.service";
import { designActions } from "../../store/design.reducer";
import { AuthService } from "../auth/services/auth.service";
import PdfViewerComponent from "./pdf-viewer";
import { DesignService } from "./services/design.service";

const { Paragraph, Title } = Typography;

const WorkFlowForm = ({
  formId,
  editorRef,
  isPublished,
  formType,
  saveDraft,
}) => {
  const pdfUploadRef = useRef(null);
  const params = useParams();
  const [uploadModal, setUploadModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorConfig, setEditorConfig] = useState(editorRef.current);
  const [timeId, setTimeoutId] = useState(null);
  const [pdfProgress, setPdfProgress] = useState(0);
  const [allPdf, setAllPdf] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (formType === FLOW_TYPE.design) {
      loadEditor();
    }
    return () => {
      setEditorInstance(null);
      dispatch(designActions.setDesignConfig(null));
      clearInterval(timeId);
      editorInstance?.destroy();
    };
  }, []);

  useEffect(() => {
    if (editorInstance && isPublished) {
      editorInstance.readOnly.toggle();
    }
  }, [isPublished]);

  useEffect(() => {
    if (params.designId) {
      getPdfs(params.designId);
    }
  }, [params?.designId, editorConfig]);

  useEffect(() => {
    if (allPdf.length) {
      const anySelectedPdf = allPdf.find((pdf) => pdf.isSelected);
      setSelectedPdf(anySelectedPdf);
    }
  }, [allPdf]);

  const handlePdfSelect = (event, selectedPdf) => {
    event.stopPropagation();
    const updatedPdfList = allPdf.map((pdf) => {
      pdf.isSelected = pdf.id === selectedPdf.id;
      return pdf;
    });
    setAllPdf(updatedPdfList);
  };

  // handle pdf deletion
  const handlePdfDeletion = async () => {
    const pathname = new URL(selectedPdf.filePath).pathname;
    const pdfContentId = pathname.split("/").pop().replace(".pdf", "");
    const deleteRes = await (
      await DesignService.removePdf(
        params.designId,
        selectedPdf.id,
        pdfContentId
      )
    ).json();
    tokenValidator(deleteRes, navigate);
    if (deleteRes?.statusCode === 200) {
      // remove the pdf from the local db
      const pdfsFromDb = await localDb.pdfConfig.get(LOCALSTORAGE_KEYS.pdfData);
      const updatedPdfs = pdfsFromDb.filter((it) => it.id !== selectedPdf.id);
      await localDb.pdfConfig.put(updatedPdfs, LOCALSTORAGE_KEYS.pdfData);
      const filteredPdfs = allPdf.filter((pdf) => pdf.id !== selectedPdf.id);
      // set the first pdf as selected
      if (filteredPdfs.length) {
        filteredPdfs[filteredPdfs.length - 1].isSelected = true;
      } else {
        setSelectedPdf(null);
      }
      setAllPdf(filteredPdfs);
    } else {
      message.error("Removing form failed");
    }
    setConfirmModal(false);
  };

  const getPdfs = async (formId) => {
    const result = await (await DesignService.getAllPdfs(formId)).json();
    if (result.statusCode === 200 && result.data?.length) {
      const mappedResult = result.data.map((pdf) => {
        return {
          ...pdf,
          filePath: `${BASE_URL}/${pdf.filePath}`,
          isSelected: false,
        };
      });
      // sort based on createdAt date
      mappedResult.sort((a, b) => {
        if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
          return 1;
        } else if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
          return -1;
        } else {
          return 0;
        }
      });
      // reverse the order of pdf
      mappedResult.reverse();
      mappedResult[mappedResult.length - 1].isSelected = true;
      setAllPdf(mappedResult);
    }
  };

  const disableCodexRedactor = () => {
    // TODO: work on the new block getting added below issue.
    const codexRedactor = document.querySelector(".codex-editor__redactor");
    // console.log(codexRedactor);
    // codexRedactor.style.paddingBottom = '100px';
  };

  const loadEditor = () => {
    // if we have some content to publish then make publish button active.
    if (editorRef.current?.contents) {
      dispatch(designActions.setEditorChanged(true));
    }

    const timeoutId = setTimeout(() => {
      const editor = new EditorJS({
        holder: "editor-container",
        readOnly: isPublished,
        autofocus: true,
        defaultBlock: "table",
        tools: {
          paragraph: {
            class: ParagraphPlugin,
            inlineToolbar: true,
          },
          list: {
            class: ListPlugin,
            inlineToolbar: true,
            config: {},
          },
          table: {
            class: TablePlugin,
            inlineToolbar: ["bold", "italic", "list"],
            config: {
              rows: 4,
              cols: 3,
              cellPadding: 4,
              border: {
                show: true,
                width: 1,
                color: "#dbdbe2",
                style: "solid",
                radius: 4,
                spacing: 0,
              },
            },
          },
        },
        data: editorRef.current?.contents || {},
        onChange: (api, change) => {
          debounce(async () => {
            const updatedConfig = await (editorInstance || editor)?.save();
            editorRef.current = {
              ...editorRef.current,
              contents: updatedConfig,
            };
            dispatch(designActions.setEditorChanged(true));
          }, 1200)();
        },
        onReady: () => {
          disableCodexRedactor();
        },
      });
      setEditorInstance(editor);
    }, 1000);

    setTimeoutId(timeoutId);
  };

  const uploadWorkflowMedia = (payload) => {
    const formData = new FormData();

    for (const name in payload) {
      formData.append(name, payload[name]);
    }

    // create request
    let request = new XMLHttpRequest();
    request.open("POST", `${BASE_URL}/workflow/uploadWorkflowMedia`);
    // set headers
    request.setRequestHeader(
      "authorization",
      `Bearer ${AuthService.getToken()}`
    );

    // upload progress event
    request.upload.addEventListener("progress", (e) => {
      // upload progress as percentage
      let percent_completed = Math.round((e.loaded / e.total) * 100);
      setTimeout(() => {
        setPdfProgress(percent_completed);
      }, 500);
    });

    // request finished event
    request.addEventListener("load", (e) => {
      // request.response holds response from the server
      const result = JSON.parse(request.response);
      setTimeout(() => {
        if (result?.statusCode === 200) {
          const pdfUrl = `${BASE_URL}/${result.data.fullUrl}`;
          let contents = [];
          if (editorConfig?.contents.length) {
            contents = editorConfig.contents;
          }
          const newEditorConfig = {
            contents: [
              ...contents,
              {
                type: "pdf",
                url: pdfUrl,
                data: null,
                scaleFactor: null,
                id: result.data.id,
              },
            ],
          };
          setEditorConfig(newEditorConfig);
          editorRef.current = { ...editorRef.current, ...newEditorConfig };
          dispatch(designActions.setEditorChanged(true));
          // call save draft to save the config to persist the pdf.
          saveDraft();
          setUploadModal(false);
          setPdfProgress(0);
        } else if (result?.statusCode === 413) {
          message.error("Please upload a file less than 100mb in size");
        }
      }, 1000);
    });

    request.send(formData);
  };

  const uploadPdf = (event) => {
    const payload = {
      formId,
      contentId: uuid(),
      file: event.target.files[0],
    };
    uploadWorkflowMedia(payload);
  };

  const moreUpload = (event) => {
    setUploadModal(true);
  };

  const PDF_UPLOAD_UI = (
    <div className="pdf-upload-container">
      {pdfProgress > 0 && pdfProgress <= 100 ? (
        <>
          <Progress type="circle" percent={pdfProgress} />
          <p>Media Successfully uploaded.</p>
        </>
      ) : (
        <div className="pdf-content-wrap">
          <FilePdfOutlined />
          <Paragraph>Drop your pdf document here.</Paragraph>
          <Button type="primary" onClick={() => pdfUploadRef?.current?.click()}>
            Upload
          </Button>
          <input
            ref={pdfUploadRef}
            type={"file"}
            accept="application/pdf"
            hidden
            onChange={uploadPdf}
          />
        </div>
      )}
    </div>
  );

  const deletePdf = (event, pdf) => {
    setSelectedPdf(pdf);
    setConfirmModal(true);
  };

  const emptyDesc = (
    <>
      <Title strong level={4}>
        No Document Selected / Added
      </Title>
      <Paragraph style={{ color: "grey" }}>
        Click on &nbsp;
        <q>
          <b>Upload Document</b>
        </q>
        &nbsp; button to add one.
      </Paragraph>
    </>
  );

  const PDF_UI = () => {
    if (editorConfig?.contents[0]?.type === "pdf") {
      return (
        <div className="pdf-list-container">
          <div className="pdf-list">
            {!isPublished ? (
              <div className="pdf-item-upload" onClick={(e) => moreUpload(e)}>
                <PlusOutlined />
                <span style={{ textAlign: "center", fontSize: 11 }}>
                  Upload Document
                </span>
              </div>
            ) : null}
            <div className="pdf-items">
              {allPdf?.map((pdf, index) => {
                return (
                  <div
                    className="pdf-item-wrap"
                    style={
                      pdf.isSelected
                        ? {
                            backgroundColor: "rgba(22, 119, 255, 0.1)",
                            border: "1px solid #1677ff",
                          }
                        : {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            border: "1px solid rgb(216, 216, 216)",
                          }
                    }
                    key={index}
                    onClick={(e) => handlePdfSelect(e, pdf)}
                  >
                    <Space
                      className="center-all"
                      style={{ width: "100%", justifyContent: "flex-end" }}
                    >
                      {/* <EditFilled className="pdf-edit-btn" /> */}
                      {!isPublished ? (
                        <CloseCircleOutlined
                          className="pdf-delete-btn"
                          onClick={(e) => deletePdf(e, pdf)}
                        />
                      ) : null}
                    </Space>
                    <FilePdfOutlined />
                    <span title={pdf.title} className="pdf-name">
                      {pdf.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {selectedPdf ? (
            <PdfViewerComponent
              editorRef={editorRef}
              readonly={false}
              hideTools={isPublished}
              from={"form"}
              currentPdf={selectedPdf}
            />
          ) : (
            <Empty style={{ margin: "auto" }} description={emptyDesc} />
          )}
        </div>
      );
    } else {
      return PDF_UPLOAD_UI;
    }
  };

  const EDITOR_UI = () => {
    return (
      <div
        id="editor-container"
        style={
          formType === FLOW_TYPE.design
            ? { marginTop: 50 }
            : { marginTop: "initial" }
        }
      ></div>
    );
  };

  return (
    <div className="form-container">
      <div className="form-content">
        {formType === FLOW_TYPE.design ? EDITOR_UI() : PDF_UI()}
        <Modal
          title="Upload More Media"
          className="upload-pdf-modal"
          open={uploadModal}
          footer={null}
          onCancel={() => setUploadModal(false)}
        >
          {PDF_UPLOAD_UI}
        </Modal>
        <ConfirmDialog
          title="Are you sure you want to delete?"
          body={{ name: selectedPdf?.title }}
          isOpen={confirmModal}
          setModalOpen={setConfirmModal}
          onSuccess={handlePdfDeletion}
        />
      </div>
    </div>
  );
};

export default WorkFlowForm;
