import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Form, Input, message, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FORM_VALIDATOR } from '../../constant/constants';
import { resetPass } from '../../store/user.effect';

const { Title, Paragraph } = Typography;

const passMatch = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter matching password.'));
    },
});


const ChangePassComponent = () => {

    const [formFilled, setFormFilled] = useState(false);
    const [form] = Form.useForm();
    const [resetBtnLoad, setResetBtnLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultPage, setResultPage] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setFormFilled(true);
        const resetToken = window.atob(searchParams.get('e'));
        const email = window.atob(searchParams.get('el'));
        const payload = { password: values.password, resetToken, email };
        setResetBtnLoading(true);
        const result = await resetPass(payload)();
        if (result.statusCode === 200) {
            setResultPage(true);
        } else {
            message.error('Error updating password');
            form.resetFields();
        }
        setResetBtnLoading(false);
    };

    const onFormChanges = (_, allValues) => {
        setFormFilled(Object.values(allValues).every(it => it) && allValues.password === allValues.confirmPass);
    }

    const navigateToSignIn = () => {
        navigate('/', { replace: true });
    }

    const FormUi = () => (
        <>
            <div className="reset-form-img"></div>
            <Title level={3} className='signup-card-head' style={{ marginBottom: 10 }}>Set new password</Title>
            <Paragraph className="signup-card-sub-head">Your new password must be different to previously used passwords.</Paragraph>
            <Form form={form} name='resetPass' validateMessages={FORM_VALIDATOR} onFinish={onFinish} onValuesChange={onFormChanges}>
                <Form.Item name='password' rules={[{ required: true, message: 'Please input your password.' }]} hasFeedback>
                    <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item name='confirmPass' rules={[{ required: true, message: 'Please confirm your password.' }, passMatch]} dependencies={['password']} hasFeedback>
                    <Input.Password
                        size="large"
                        placeholder="Confirm Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item className='signup-btn-container' noStyle>
                    <Button
                        type="primary"
                        className='signup-btn'
                        htmlType="submit"
                        loading={resetBtnLoad}
                        disabled={!formFilled}
                        style={formFilled ? { backgroundColor: '#0faf51' } : { backgroundColor: '#e5e5e5' }}
                    >Reset Password</Button>
                </Form.Item>
            </Form>

        </>
    )

    const ResultUi = () => (
        <>
            <div className="reset-status-img"></div>
            <Title level={3} className='signup-card-head' style={{ marginBottom: 10 }}>Password reset</Title>
            <Paragraph className='signup-card-sub-head no-margin'>Your password has been successfully reset. </Paragraph>
            <Paragraph className="signup-card-sub-head">Click below to log in.</Paragraph>
            <div className='center-all' style={{ width: '100%' }}>
                <Button type="primary"
                    className='signup-btn'
                    htmlType="submit"
                    onClick={navigateToSignIn}
                    style={{ backgroundColor: '#0faf51', width: 200 }}
                >Continue</Button>
            </div>
        </>
    )


    return (
        <section className='signup-wrapper'>
            <header className='header-wrapper'>
                <div className='logo-container' onClick={navigateToSignIn}>
                    <img src='assets/logo.png' alt='WFC' />
                </div>
                {/* <div className='signup-nav'>
                    <Title level={5} className='no-margin'>Have an account? </Title> <Button className='signIn-btn' onClick={navigateToSignIn}>Sign In</Button>
                </div> */}
            </header>
            <main className='signup-middle'>
                <div className='signup-card'>
                    {
                        resultPage ? ResultUi() : FormUi()
                    }
                </div>
            </main>
        </section>
    );
}

export default ChangePassComponent;