export const LocalStorageService = {
    setToLocalStorage: (key, value) => {
        let keyHashed = key;
        let payload = JSON.stringify(value);
        if (process.env.NODE_ENV === 'production') {
            // hash the key
            keyHashed = generateHash(key).toString();
            // encode the payload before storing
            payload = window.btoa(JSON.stringify(value));
        }

        window.localStorage.setItem(keyHashed, payload);
    },

    getFromLocalStorage: (key) => {
        let keyHashed = key;
        if (process.env.NODE_ENV === 'production') {
            keyHashed = generateHash(key).toString();
        }
        const value = window.localStorage.getItem(keyHashed);
        if(!value) {
            return null;
        }
        let parsed = process.env.NODE_ENV === 'production' ? JSON.parse(window.atob(value)) : JSON.parse(value);
        return parsed;
    },

    clearStorage: (key) => {
        window.localStorage.clear();
    },

    removeItem: (key) => {
        let keyHashed = key;
        if(process.env.NODE_ENV === 'production') {
            keyHashed = generateHash(key).toString();
        }
        window.localStorage.removeItem(keyHashed);
    }



}

const generateHash = (value) => {
    return value.split('').reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
    }, 0);
}