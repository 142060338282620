import { Modal, Typography } from "antd";

const { Paragraph } = Typography;

const ConfirmDialog = ({ title, body, isOpen, setModalOpen, onSuccess }) => {
    return (
        <Modal title={title}
            centered
            bodyStyle={
                {
                    minHeight: '25px',
                    minWidth: '200px'
                }
            }
            maskClosable={false}
            width={350}
            open={isOpen}
            onOk={() => onSuccess()}
            onCancel={() => setModalOpen(false)}
        >
            <Paragraph>{`${body?.name}`}</Paragraph>
        </Modal>
    );
}

export default ConfirmDialog;