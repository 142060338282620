import { createSlice } from "@reduxjs/toolkit";

const designSlice = createSlice({
  name: "design",
  initialState: {
    config: null,
    roles: [],
    editorChanged: false,
    recipients: [],
    widgetCount: 0,
    isMenuCollapsed: false,
  },
  reducers: {
    setDesignConfig: (state, action) => {
      state.config = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setEditorChanged: (state, action) => {
      state.editorChanged = action.payload;
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    setWidgetCount: (state, action) => {
      state.widgetCount = action.payload;
    },
    setToolsCollapse: (state, action) => {
      state.isMenuCollapsed = action.payload;
    },
  },
});

export const designActions = designSlice.actions;

export default designSlice;
