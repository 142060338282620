export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.zarfh.com:3001"
    : "http://localhost:3001";
export const LOCALSTORAGE_KEYS = {
  token: "TK",
  resendLock: "RL",
  pdfData: "PF",
  docPageAuth: "DOCA",
  recipients: "RECI",
};
export const EMAIL_REGEX =
  /([-!#-'*+\/-9=?A-Z^-~]+(\.[-!#-'*+\/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)/;

export const DOC_URL_REGEX = /(?:^|\W)doc(?:$|\W)/;

export const ROLE_COLORS = {
  APPROVER: "blue",
  CREATOR: "lime",
  RECEIVER: "orange",
  GATEKEEPER: "yellow",
};

export const ROLES = {
  gatekeeper: "GATEKEEPER",
  approver: "APPROVER",
  creator: "CREATOR",
  receiver: "RECEIVER",
};

export const ROLE_STATUS = {
  approved: "APPROVED",
  rejected: "REJECTED",
  pending: "PENDING",
  received: "RECEIVED",
  review: "REVIEWED",
};

export const ROLE_STATUS_COLOR_MAP = {
  [ROLE_STATUS.approved]: "#0faf51",
  [ROLE_STATUS.rejected]: "#D22B2B",
  [ROLE_STATUS.pending]: "#1677ff",
  [ROLE_STATUS.received]: "#FFBF00",
};

const PENDING_ROLE_MAPPING = {
  [ROLES.gatekeeper]: "Review",
  [ROLES.approver]: "Approve",
  [ROLES.receiver]: "Export As Pdf",
  [ROLES.creator]: "Export As Pdf",
};

export const DIALOG_UI_CONTENT = {
  [ROLE_STATUS.review]: {
    title: "You want to review this document",
    btnName: "Yes, Review",
  },
  [ROLE_STATUS.approved]: {
    title: "You want to approve this document",
    btnName: "Yes, Approve",
  },
  [ROLE_STATUS.rejected]: {
    title: "You want to reject this document",
    btnName: "Yes, Reject",
  },
};

export const PROCEED_BTN_CONFIG_MAP = (roleStatus, currentRole) => {
  const roleConfigMapping = {
    [ROLE_STATUS.pending]: {
      color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.pending],
      name: PENDING_ROLE_MAPPING[currentRole],
      disabled: false,
    },
    [ROLE_STATUS.approved]: {
      color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.approved],
      name: "APPROVED",
      disabled: true,
    },
    [ROLE_STATUS.review]: {
      color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.approved],
      name: "REVIEWED",
      disabled: true,
    },
    [ROLE_STATUS.rejected]: {
      color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.rejected],
      name: "REJECTED",
      disabled: true,
    },
    [ROLE_STATUS.received]: {
      color: ROLE_STATUS_COLOR_MAP[ROLE_STATUS.received],
      name: "COMPLETED",
      disabled: true,
    },
    disabled: {
      color: "#cbcbcb",
      name: "---",
      disabled: true,
    },
  };

  const result = roleConfigMapping[roleStatus];
  return result;
};

export const EDITOR_MODE = {
  design: "DESIGN",
  view: "VIEW",
};

export const OTP_CIRCLES = [
  {
    id: 1,
    value: "",
  },
  {
    id: 2,
    value: "",
  },
  {
    id: 3,
    value: "",
  },
  {
    id: 4,
    value: "",
  },
];

export const FORM_VALIDATOR = {
  required: "This field is required.",
  types: {
    email: "Please provide a valid email address.",
  },
};

export const USER_ACTIONS = {
  internal: 0,
  external: 1,
};

export const USER_VERIFY_TYPE = {
  internal: 0,
  external: 1,
};

export const FLOW_TYPE = Object.freeze({
  design: 0,
  upload: 1,
});

export const WIDGET_TYPES = Object.freeze({
  signature: "Signature",
  initials: "Signature Initial",
  signDate: "Signature Date",
  text: 'Text'
});

export const PDF_WIDGETS = [
  {
    label: "Sign",
    subLabel: "here",
    value: "signature",
    icon: `/assets/tools/signature.svg`,
    isSelected: false,
    width: 190,
    height: 70,
    minWidth: 180,
    minHeight: 60,
    maxWidth: 280,
    maxHeight: 100,
  },
  {
    label: "Initials",
    subLabel: "here",
    value: "initials",
    icon: `/assets/tools/DS.svg`,
    isSelected: false,
    width: 80,
    height: 70,
    minWidth: 70,
    minHeight: 60,
    maxWidth: 120,
    maxHeight: 80,
  },
  {
    label: "Signing Date",
    subLabel: "here",
    value: "signDate",
    icon: "/assets/tools/date.svg",
    isSelected: false,
    width: 100,
    height: 55,
    minWidth: 70,
    minHeight: 20,
    maxWidth: 150,
    maxHeight: 80,
  },
  {
    label: "Text",
    subLabel: "here",
    value: "text",
    icon: "/assets/tools/text.svg",
    isSelected: false,
    width: 200,
    height: 60,
    minWidth: 100,
    minHeight: 30,
    maxWidth: "100%",
    maxHeight: "100%",
  },
];

export const DRAWING_TOOLS = ["pen", "eraser"];

export const CURSOR_WIDGETS_MAP = Object.freeze({
  eraser: "cursor: url(/assets/tools/eraser-box.svg) 12 12, auto;",
  pen: "cursor: url(/assets/tools/pencil-draw.svg)  0 0, auto;",
});

export const TABLE_CONSTANTS = {
  table_header: 54,
  table_paginator: 32,
  table_margin: 20,
};

export const SIG_TEMPLATES = [
  {
    font: "Babylonica-Regular",
    isSelected: false,
  },
  {
    font: "BethEllen-Regular",
    isSelected: false,
  },
  {
    font: "Caveat-VariableFont_wght",
    isSelected: false,
  },
  {
    font: "Charm-Regular",
    isSelected: false,
  },
  {
    font: "ComforterBrush-Regular",
    isSelected: false,
  },
  {
    font: "Estonia-Regular",
    isSelected: false,
  },
  {
    font: "FuzzyBubbles-Regular",
    isSelected: false,
  },
  {
    font: "Mansalva-Regular",
    isSelected: false,
  },
  {
    font: "Mynerve-Regular",
    isSelected: false,
  },
  {
    font: "QwitcherGrypen-Regular",
    isSelected: false,
  },
  {
    font: "WaterBrush-Regular",
    isSelected: false,
  },
  {
    font: "Whisper-Regular",
    isSelected: false,
  },
];

export const RESIZE_HANDLES_STYLES = {
  bottom: {
    background: "#000", // Background color
    width: `100%`, // Width of the handle
    height: "1px", // Height of the handle
    bottom: `0px`,
  },
  bottomLeft: {
    background: "#000",
    width: "5px",
    height: "5px",
    left: `-2px`,
    bottom: `-2px`,
  },
  bottomRight: {
    background: "#000",
    width: "5px",
    height: "5px",
    bottom: `-2px`,
    right: `-2px`,
  },
  left: {
    background: "#000",
    width: "1px",
    height: `100%`,
    left: `0px`,
  },
  right: {
    background: "#000",
    width: "1px",
    height: `100%`,
    right: `0px`,
  },
  top: {
    background: "#000",
    width: `100%`,
    height: "1px",
    top: "0px",
  },
  topLeft: {
    background: "#000",
    width: "5px",
    height: "5px",
    left: `-2px`,
    top: `-2px`,
  },
  topRight: {
    background: "#000",
    width: "5px",
    height: "5px",
    right: `-2px`,
    top: `-2px`,
  },
};

export const WIDGET_CONTEXT_MENU = [
  {
    key: "del",
    label: "delete",
  },
];
