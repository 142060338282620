import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  List,
  Radio,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AvatarMenu from "../../common/avatar-menu";
import ConfirmDialog from "../../common/confirm-dialog";
import NotificationComponent from "../../common/notification";
import { tokenValidator } from "../../common/token-validator";
import {
  BASE_URL,
  LOCALSTORAGE_KEYS,
  SIG_TEMPLATES,
} from "../../constant/constants";
import { LocalStorageService } from "../../services/localstorage.service";
import { WorkflowService } from "../document/services/workflow.service";
import { SignatureService } from "./services/signature.service";
import SignatureInitialComponent from "./signature-initials";
import SignatureNameComponent from "./signature-name";

const { Paragraph, Title } = Typography;

const SignatureComponent = () => {
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [signTemplates, setSignTemplates] = useState(SIG_TEMPLATES);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentSignature, setCurrentSignature] = useState(null);
  const [allSignsImgs, setAllSignsImg] = useState([]);
  const [signTemps, setSignTemps] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedSign, setSelectedSign] = useState(null);
  const [t2SaveDisable, setT2SaveDisable] = useState(true);
  const [t2SaveLoader, setT2SaveLoader] = useState(false);
  const [t2UploadLoader, setT2UploadLoader] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState("1");
  const [formValues, setFormValues] = useState({
    fullName: "",
    initials: "",
  });

  const newSignatureTemplate = async () => {
    const selectedFont = signTemplates.find((it) => it.isSelected)?.font;

    const payload = {
      signatureName: formValues.fullName,
      signatureInitials: formValues.initials,
      signatureFont: selectedFont,
      adoptionDate: moment().utc(true).toDate(),
      isDefault: true,
    };

    setBtnLoading(true);
    const result = await (await SignatureService.addSignature(payload)).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200 && result.data) {
      message.success("Signature Saved Successfully");
      const currentSign = {
        name: formValues.fullName,
        initials: formValues.initials,
        font: selectedFont,
        serial: currentUser.id,
        adoptionDate: result.data.adoptionDate,
        signId: result.data.id,
      };
      setCurrentSignature(currentSign);
    } else {
      message.error("Error Saving Signature, Try again in a bit");
    }
    setBtnLoading(false);
    setDisableBtn(true);
  };

  const updateSignatureTemplate = async (signId) => {
    const selectedFont = signTemplates.find((it) => it.isSelected)?.font;

    const updatedPayload = {
      signatureFont: selectedFont,
      adoptionDate: moment().utc(true).toDate(),
      isDefault: true,
    };

    setBtnLoading(true);

    const result = await (
      await SignatureService.updateSignature(updatedPayload, signId)
    ).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      message.success("Signature Updated Successfully");
      const currentSign = {
        name: formValues.fullName,
        initials: formValues.initials,
        font: selectedFont,
        serial: currentUser.id,
        adoptionDate: updatedPayload.adoptionDate,
        signId,
      };
      setCurrentSignature(currentSign);
    } else {
      message.error("Error Saving Signature, Try again in a bit");
    }
    setBtnLoading(false);
    setDisableBtn(true);
  };

  // update the name on input field and internal state.
  const onNameChange = (event) => {
    const newFormValues = {
      ...formValues,
      fullName: event.target.value,
    };
    setFormValues(newFormValues);
  };

  // update the initials on input field and internal state.
  const onInitialsChange = (event) => {
    const newFormValues = {
      ...formValues,
      initials: event.target.value,
    };
    setFormValues(newFormValues);
  };

  const getCurrentUser = async () => {
    const token = LocalStorageService.getFromLocalStorage(
      LOCALSTORAGE_KEYS.token
    );

    const result = await (
      await SignatureService.findUser({ email: token.email })
    ).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200 && result.data) {
      setCurrentUser(result.data);
    }
  };

  // make call to backend to get details of current user
  useEffect(() => {
    if (!currentUser) {
      getCurrentUser();
    }
  }, [currentUser]);

  // get current signature from backend
  const getCurrentSignature = async () => {
    const result = await (await SignatureService.getCurrentSignature()).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200 && result.data) {
      const {
        signatureName,
        signatureInitials,
        signatureFont,
        id: signId,
      } = result.data;
      const currentSignature = {
        name: signatureName,
        initials: signatureInitials,
        font: signatureFont,
        serial: currentUser.id,
        adoptionDate: result.data.adoptionDate,
        signId,
      };
      setCurrentSignature(currentSignature);
      // set full name and initials so it automatically generates templates.
      setFormValues({
        fullName: currentSignature.name,
        initials: currentSignature.initials,
      });
      // update sign-templates list by already selected font from backend
      const updatedSignTemplates = signTemplates.map((it) => {
        it.isSelected = it.font === currentSignature.font;
        return it;
      });

      setSignTemplates(updatedSignTemplates);

      // if signature details are not available then switch tab to signature upload
      if (
        !(signatureName && signatureFont) ||
        !(signatureInitials && signatureFont)
      ) {
        setTabActiveKey("2");
        // update the signatures as selected
        const updatedSigns = allSignsImgs.map((sign) => {
          sign.isSelected = sign.id === signId;
          return sign;
        });
        setAllSignsImg(updatedSigns);
      }
    }
  };

  useEffect(() => {
    if (!currentSignature && currentUser) {
      getCurrentSignature();
    }
  }, [currentSignature, currentUser]);

  const getAllSignatureImgs = async () => {
    const result = await (await WorkflowService.listSignature()).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200 && result.data?.length) {
      const imgSignsOnly = result.data
        .filter((sign) => !!sign.imagePath)
        .map((sign) => {
          return {
            ...sign,
            isSelected: false,
          };
        });
      setAllSignsImg(imgSignsOnly);

      const signTemps = result.data.filter((signs) => !signs.imagePath);
      setSignTemps(signTemps);
    }
  };

  useEffect(() => {
    if (!allSignsImgs.length) {
      getAllSignatureImgs();
    }
  }, []);

  const onSignSelect = (selectedSign) => {
    const updatedSigns = allSignsImgs.map((sign) => {
      sign.isSelected = selectedSign.id === sign.id;
      return sign;
    });
    setSelectedSign(selectedSign);
    setAllSignsImg(updatedSigns);
    setT2SaveDisable(false);
  };

  window.document.onclick = (event) => {
    const propertyContainer = window.document.getElementsByClassName(
      "ant-card ant-card-bordered signature-selected"
    );
    if (!propertyContainer[0]?.contains(event.target)) {
      const updatedSigns = allSignsImgs.map((sign) => {
        sign.isSelected = false;
        return sign;
      });
      setAllSignsImg(updatedSigns);
      setT2SaveDisable(true);
    }
  };

  const NoSignatureGen = (
    <>
      <Title strong level={5}>
        No signatures generated
      </Title>
      <Paragraph style={{ color: "grey" }}>
        Enter your full name and initials to generate your signatures.
      </Paragraph>
    </>
  );

  const NoSignature = (
    <>
      <Title strong level={5}>
        Yon don't have any signature yet.
      </Title>
      <Paragraph style={{ color: "grey" }}>
        Generate one by using your name and initials.
      </Paragraph>
    </>
  );

  const handleSaveTemplate = () => {
    if (signTemps.length) {
      // already sign template exist update it
      const signId = signTemps[0].id;
      updateSignatureTemplate(signId);
    } else {
      // add new sign template
      newSignatureTemplate();
    }
  };

  // signature template ui
  const SignTemplateUI = (
    <Space direction="vertical" align="start" style={{ display: "flex" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
        className="signature-name-wrap"
        size={32}
      >
        <Space direction="vertical">
          <Paragraph
            strong
            className="email-error"
            style={{ margin: 0, width: 250, minWidth: "fit-content" }}
          >
            Full Name
          </Paragraph>
          <Input
            placeholder="Ex: John Doe"
            maxLength={64}
            style={{ width: 250 }}
            value={formValues?.fullName}
            onChange={onNameChange}
            disabled={!!currentSignature?.name || signTemps[0]?.signatureName}
          />
        </Space>
        <Space direction="vertical">
          <Paragraph
            strong
            className="email-error"
            style={{ margin: 0, width: 250, minWidth: "fit-content" }}
          >
            Initials
          </Paragraph>
          <Input
            placeholder="Ex: JD"
            value={formValues?.initials}
            style={{ width: 250 }}
            maxLength={5}
            onChange={onInitialsChange}
            disabled={
              !!currentSignature?.initials || signTemps[0]?.signatureInitials
            }
          />
        </Space>
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="primary"
            style={{ margin: "28px 0 0 0" }}
            onClick={handleSaveTemplate}
            disabled={disableBtn}
            loading={btnLoading}
          >
            Save
          </Button>
        </Space>
      </Space>
      {formValues?.fullName || formValues?.initials ? (
        <List
          itemLayout="horizontal"
          dataSource={signTemplates}
          renderItem={(item, index) => (
            <List.Item>
              <Space
                className="sign-list-radio-wrap"
                direction="horizontal"
                align="start"
                style={{ display: "flex", width: "100%" }}
                size={24}
              >
                <Radio
                  value={item.font}
                  onChange={(event) => onRadioChange(event, index)}
                  checked={item.isSelected}
                ></Radio>
                <SignatureNameComponent
                  name={formValues?.fullName}
                  font={item?.font}
                  serial={currentUser?.id}
                ></SignatureNameComponent>
                <SignatureInitialComponent
                  initials={formValues?.initials}
                  font={item?.font}
                  serial={currentUser?.id}
                ></SignatureInitialComponent>
              </Space>
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description={NoSignatureGen}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ fontSize: 14 }}
          style={{ margin: "3% 0" }}
        />
      )}
    </Space>
  );

  const deleteSign = (event, selectedSign) => {
    event.stopPropagation();
    setSelectedSign(selectedSign);
    setConfirmModal(true);
  };

  const DeleteButton = (sign) => (
    <Tooltip title="Delete">
      <DeleteFilled key="delete" onClick={(event) => deleteSign(event, sign)} />
    </Tooltip>
  );

  const handleSignDeletion = async () => {
    const result = await (
      await WorkflowService.removeSignature(selectedSign.id)
    ).json();
    setConfirmModal(false);
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      // remove the sign from internal state list
      const updatedSigns = allSignsImgs.filter(
        (sign) => sign.id !== selectedSign.id
      );
      setAllSignsImg(updatedSigns);
      // update the current selected signature with new signature img ui
    }
  };

  const saveSign = async () => {
    setT2SaveDisable(true);
    setT2SaveLoader(true);
    const payload = {
      isDefault: true,
      adoptionDate: moment().utc(true).toDate(),
    };
    const result = await (
      await SignatureService.updateSignature(payload, selectedSign.id)
    ).json();
    tokenValidator(result, navigate);
    if (result.statusCode === 200) {
      message.success("Signature Updated Successfully");
      getCurrentSignature();
    }
    setT2SaveLoader(false);
    setT2SaveDisable(false);
  };

  const uploadSignature = async ({ file, onSuccess }) => {
    setT2UploadLoader(true);
    const result = await (
      await WorkflowService.uploadSignature({ file })
    ).json();
    tokenValidator(result, navigate);
    setT2UploadLoader(false);
    if (result.statusCode === 200) {
      getAllSignatureImgs();
      onSuccess("ok");
    } else {
      message.error("Error uploading signature.");
    }
  };

  const NoSignImgs = (
    <>
      <Title strong level={5}>
        Yon don't have any signature added yet.
      </Title>
      <Paragraph style={{ color: "grey" }}>
        Click on upload button to add your signature.
      </Paragraph>
    </>
  );

  const SignImageUI = (
    <Space direction="horizontal" className="sign-upload-wrap">
      <Space
        direction="horizontal"
        align="start"
        style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
        className="signature-name-wrap"
        size={32}
      >
        <ImgCrop rotate>
          <Upload
            accept="image/png, image/jpeg, image/jpg"
            customRequest={uploadSignature}
            fileList={null}
          >
            <Button
              type="primary"
              style={{ margin: "5px 0" }}
              loading={t2UploadLoader}
            >
              Upload
            </Button>
          </Upload>
        </ImgCrop>
        <Button
          type="primary"
          style={{ margin: "5px 0" }}
          disabled={t2SaveDisable}
          loading={t2SaveLoader}
          onClick={saveSign}
        >
          Save
        </Button>
      </Space>
      {allSignsImgs?.length ? (
        <Row gutter={[14, 14]} style={{ overflowY: "auto" }}>
          {allSignsImgs.map((sign) => {
            return (
              <Col style={{ minWidth: 100 }} key={sign.id}>
                <Card
                  onClick={() => onSignSelect(sign)}
                  style={{
                    width: 100,
                    border: "2px solid transparent",
                  }}
                  className={sign.isSelected ? "signature-selected" : "initial"}
                  cover={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 5px",
                      }}
                    >
                      <img
                        alt="signature"
                        style={{ width: 50, height: 50 }}
                        src={`${BASE_URL}/signature/download/${sign.id}`}
                      />
                    </div>
                  }
                  bodyStyle={{ padding: 0 }}
                  actions={[DeleteButton(sign)]}
                ></Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty
          description={NoSignImgs}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ fontSize: 14 }}
          style={{ margin: "3% 0" }}
        />
      )}
      <ConfirmDialog
        title="Are you sure you want to delete?"
        body={{ name: "" }}
        isOpen={confirmModal}
        setModalOpen={setConfirmModal}
        onSuccess={handleSignDeletion}
      />
    </Space>
  );

  const tabItems = [
    {
      key: "1",
      label: "Signature Templates",
      children: SignTemplateUI,
    },
    {
      key: "2",
      label: "Upload Signatures",
      children: SignImageUI,
    },
  ];

  const onRadioChange = (event, index) => {
    const grouped = signTemplates.map((it, i) => {
      it.isSelected = i === index;
      return it;
    });
    setSignTemplates(grouped);
    setDisableBtn(false);
  };

  const onTabChange = (tabIndex) => {
    setTabActiveKey(tabIndex);
    if (tabIndex == 1 && signTemps.length) {
      // load name generation template
      const signTemp = signTemps[0];
      setFormValues({
        fullName: signTemp.signatureName,
        initials: signTemp.signatureInitials,
      });
    }
  };

  const CardTitle = () => {
    return (
      <div className="card-title-custom">
        <Title level={5} style={{ margin: "4px 0px 0px 0px" }}>
          Signature & Initials
        </Title>
        <Paragraph
          italic
          style={{
            margin: "4px 0px 0px 0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "lighter",
          }}
        >
          Adopted on:{" "}
          {moment(currentSignature.adoptionDate)
            .utc()
            .format("Do MMM YYYY hh:mm A")}
        </Paragraph>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <header className="header-wrapper">
        <div className="logo-container">
          <img src="/assets/logo.png" alt="WFC" />
        </div>
        <div className="dash-nav">
          <Space size="middle">
            {/* <Button type="primary" className="new-btn" onClick={createNewForm}>
              Create Form
            </Button> */}
            <AvatarMenu />
            <NotificationComponent />
          </Space>
        </div>
      </header>
      <div className="dashboard-content">
        <Space
          direction="vertical"
          align="start"
          size="large"
          style={{ display: "flex", width: "100%", height: "100%" }}
          className="sign-space-container"
        >
          <Space
            direction="vertical"
            size="small"
            align="start"
            style={{ display: "flex" }}
          >
            <Paragraph italic>Showing current selected signature</Paragraph>
            {currentSignature ? (
              <Card
                style={{ maxWidth: "fit-content", flex: 4 }}
                title={CardTitle()}
                size="small"
                bordered={false}
                bodyStyle={{ maxWidth: 600, width: '100%' }}
              >
                <div className="curr-sign-wrap">
                  {currentSignature?.font ? (
                    <>
                      <SignatureNameComponent
                        name={currentSignature?.name}
                        font={currentSignature?.font}
                        serial={currentSignature?.serial}
                      ></SignatureNameComponent>
                      <SignatureInitialComponent
                        initials={currentSignature?.initials}
                        font={currentSignature?.font}
                        serial={currentSignature?.serial}
                      ></SignatureInitialComponent>
                    </>
                  ) : (
                    <>
                      <img
                        alt="signature"
                        style={{ width: 100, height: 100 }}
                        src={`${BASE_URL}/signature/download/${currentSignature.signId}`}
                      />
                    </>
                  )}
                </div>
              </Card>
            ) : (
              <Empty
                description={NoSignature}
                imageStyle={{ width: 50, height: 45 }}
              />
            )}
          </Space>
          <Space
            direction="vertical"
            size="small"
            align="start"
            style={{
              display: "flex",
              width: "inherit",
              columnGap: "0px",
              rowGap: "0px",
            }}
          >
            <Paragraph strong>Choose your signature</Paragraph>
            <Tabs
              defaultActiveKey="1"
              activeKey={tabActiveKey}
              items={tabItems}
              onChange={onTabChange}
              style={{ height: "100%" }}
            />
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default SignatureComponent;
