import { BASE_URL } from "../../../constant/constants";
import { AuthService } from "../../auth/services/auth.service";

export const DesignService = {
  uploadFormLogo: (payload) => {
    const formData = new FormData();
    for (const name in payload) {
      formData.append(name, payload[name]);
    }
    return fetch(`${BASE_URL}/form/uploadFormLogo`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
      body: formData,
    });
  },
  removeFormLogo: (formId) => {
    return fetch(`${BASE_URL}/form/removeFormLogo/${formId}`, {
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  },
  removePdf: (formId, pdfId, pdfContentId) => {
    return fetch(
      `${BASE_URL}/media/delete/${formId}/${pdfId}/${pdfContentId}`,
      {
        headers: {
          authorization: `Bearer ${AuthService.getToken()}`,
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );
  },
  publishForm: (payload, formId) => {
    return fetch(`${BASE_URL}/form/publish/${formId}`, {
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    });
  },
  getRoles: () => {
    return fetch(`${BASE_URL}/role/list`, {
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  },

  getAllPdfs: (formId) => {
    return fetch(`${BASE_URL}/media/list/${formId}`, {
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  },

  removeWorkflowImage: (contentId) => {
    return fetch(`${BASE_URL}/workflow/remove/${contentId}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
  },

  fetchUsersByEmail: (payload) => {
    return fetch(`${BASE_URL}/user/findUsers`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },

  getRecipientsByWorkflow: (workflowId) => {
    return fetch(`${BASE_URL}/recipient/list/${workflowId}`, {
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  },

  fetchSignaturesByEmail: (payload) => {
    return fetch(`${BASE_URL}/signature/listByEmail`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${AuthService.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },
};
