import { Navigate, useLocation } from "react-router-dom";
import { DOC_URL_REGEX, LOCALSTORAGE_KEYS } from "../constant/constants";
import { LocalStorageService } from "../services/localstorage.service";
import { isTokenValid } from "./token-validator";

export const GuardedRoute = ({ children }) => {
  const token = LocalStorageService.getFromLocalStorage(
    LOCALSTORAGE_KEYS.token
  );
  const location = useLocation();
  if (!isTokenValid(token)) {
    if (location.pathname.match(DOC_URL_REGEX)) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get("ty") === "ext") {
        return <Navigate to="/emailAuth" replace />;
      }
      const redirectUrl = `${window.location.origin}${location.pathname}${location.search}`;
      LocalStorageService.setToLocalStorage(
        LOCALSTORAGE_KEYS.docPageAuth,
        redirectUrl
      );
    }
    LocalStorageService.removeItem(LOCALSTORAGE_KEYS.token);
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};
