import { BASE_URL } from '../../../constant/constants';
import { AuthService } from '../../auth/services/auth.service';

export const DashboardService = {
    newForm: (payload) => {
        return fetch(`${BASE_URL}/form/newForm`, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
    },

    allForms: (_) => {
        return fetch(`${BASE_URL}/form/list`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            }
        });
    },

    deleteForm: (formId) => {
        return fetch(`${BASE_URL}/form/remove/${formId}`, {
            method: 'DELETE',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            }
        });
    },

    getForm: (formId) => {
        return fetch(`${BASE_URL}/form/get/${formId}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            }
        });
    },

    updateForm: (payload, formId) => {
        return fetch(`${BASE_URL}/form/update/${formId}`, {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
    },

    allFormsWithWorkflow: () => {
        return fetch(`${BASE_URL}/form/listWorkflows`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${AuthService.getToken()}`,
                'Content-Type': 'application/json'
            }
        });
    }
}