import { BASE_URL, LOCALSTORAGE_KEYS, USER_VERIFY_TYPE } from '../../../constant/constants';
import { LocalStorageService } from '../../../services/localstorage.service';
export const AuthService = {
    registerUser: (userPayload) => {
        return fetch(`${BASE_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    signInUser: (userPayload) => {
        return fetch(`${BASE_URL}/signIn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    verifyUser: (userPayload, verifyType) => {
        const entrypoint = verifyType === USER_VERIFY_TYPE.external ? 'verifyExternal': 'verify';
        return fetch(`${BASE_URL}/${entrypoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    forgotPass: (userPayload) => {
        return fetch(`${BASE_URL}/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    verifyEmail: (userPayload) => {
        return fetch(`${BASE_URL}/verifyEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    resetPass: (userPayload) => {
        return fetch(`${BASE_URL}/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPayload)
        });
    },

    getToken: () => {
        return LocalStorageService.getFromLocalStorage(LOCALSTORAGE_KEYS.token);
    },

    logout: (navigate) => {
        LocalStorageService.clearStorage();
        navigate('/', { replace: true });
    }
}