import { configureStore } from "@reduxjs/toolkit";
import appSlice from './app.reducer';
import authSlice from './auth.reducer';
import designSlice from './design.reducer';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        app: appSlice.reducer,
        design: designSlice.reducer
    }
});

export default store;   