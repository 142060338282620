import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Typography, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FORM_VALIDATOR, LOCALSTORAGE_KEYS } from "../../constant/constants";
import { LocalStorageService } from "../../services/localstorage.service";
import { appActions } from "../../store/app.reducer";
import { login } from "../../store/user.effect";

const { Title } = Typography;

const SignInComponent = () => {
  const dispatch = useDispatch();
  const [formFilled, setFormFilled] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values) => {
    dispatch(appActions.setIsLoading(true));
    login(values)()
      .then((data) => {
        if (data?.statusCode == 200) {
          LocalStorageService.setToLocalStorage(
            LOCALSTORAGE_KEYS.token,
            data.authToken
          );
          // if we have the flag set by guard for doc auth then proceed to route to that url instead of dashboard.
          const redirectUrl = LocalStorageService.getFromLocalStorage(
            LOCALSTORAGE_KEYS.docPageAuth
          );
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            navigate("/dashboard", { replace: true });
          }
        } else {
          message.error(`Error signing user`);
          form.resetFields();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(appActions.setIsLoading(false));
      });
  };

  const navigateToSignUp = () => {
    navigate("/register", { replace: true });
  };

  const onFormChanges = (_, allValues) => {
    setFormFilled(Object.values(allValues).every((it) => it));
  };

  const handleForgotPass = () => {
    navigate("/forgot", { replace: true });
  };

  return (
    <section className="signup-wrapper">
      <header className="header-wrapper">
        <div className="logo-container">
          <img src="assets/logo.png" alt="WFC" />
        </div>
        <div className="signup-nav">
          <Title level={5} className="no-margin">
            Don't have an account?{" "}
          </Title>
          <Button className="signIn-btn" onClick={navigateToSignUp}>
            Sign Up
          </Button>
        </div>
      </header>
      <main className="signup-middle">
        <div className="signup-card">
          <Title level={3} className="signup-card-head">
            Sign In
          </Title>
          <Form
            form={form}
            name="signup"
            validateMessages={FORM_VALIDATOR}
            onFinish={onFinish}
            onValuesChange={onFormChanges}
          >
            <Form.Item name="email" rules={[{ type: "email", required: true }]}>
              <Input size="large" placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              className="pass-container"
              name="password"
              rules={[
                { required: true, message: "Please input your password." },
              ]}
              hasFeedback
            >
              <Input.Password
                size="large"
                placeholder="Enter Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item className="forgot-container">
              <a className="forgot-pass" onClick={handleForgotPass}>
                Forgot password
              </a>
            </Form.Item>
            <Form.Item className="signup-btn-container" noStyle>
              <Button
                type="primary"
                className="signup-btn"
                htmlType="submit"
                disabled={!formFilled}
                style={
                  formFilled
                    ? { backgroundColor: "#0faf51" }
                    : { backgroundColor: "#e5e5e5" }
                }
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </main>
    </section>
  );
};

export default SignInComponent;
